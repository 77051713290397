import * as types from "../types";

/**
 * @typedef {{lat: number|string, lng: number|string }} Coords
 *
 * @type {{[index: string]: { coords: Coords }}}
 */
const INIT_STATE = {};

export default function driverLocationReducer(state = INIT_STATE, action) {
  const { payload } = action;
  switch (action.type) {
    case types.SET_DRIVER_LOCATION: {
      const newCoords = payload.coords.split(",");
      const updatedState = {
        ...state,
        [payload.deviceId]: {
          ...payload,
          coords: {
            lat: newCoords[0],
            lng: newCoords[1],
          },
        },
      };
      return updatedState;
    }
    case types.REMOVE_DRIVER_LOCATION: {
      const filteredState = { ...state };
      delete filteredState[payload.deviceId];
      return filteredState;
    }
    default:
      return state;
  }
}
