/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

// @ts-check
import { normalize } from "normalizr";
import produce from "immer";
import * as types from "../types";
import * as schema from "../schemas/tableOrderSchema";

const defaultState = {
  entities: {
    tableOrders: {},
    orderItems: {}, // same as food array
  },
  result: [],
};

const reducer = (draft, action) => {
  const { tableOrders, orderItems } = draft.entities;

  switch (action.type) {
    case types.NORMALIZE_TABLE_ORDERS: {
      return normalize(action.payload.orders, [schema.tableOrder]);
    }

    case types.ADD_FOOD_TO_ORDER: {
      const { orderItem, orderId } = action.payload;
      orderItems[orderItem.orderDetailId] = orderItem;
      // add ID referance
      tableOrders[orderId].orderItems.push(orderItem.orderDetailId);
      return;
    }

    case types.CHANGE_ORDER_STATUS: {
      const { orderId, status } = action.payload;
      tableOrders[orderId].status = status;
      return;
    }

    case types.CHANGE_FOOD_DELETED: {
      const { orderDetailId, deleted } = action.payload;
      orderItems[orderDetailId].deleted = deleted;
      return;
    }

    case types.RESET_TABLE: {
      draft.result = draft.result.filter(
        (id) => tableOrders[id].status !== "ACCEPTED"
      );
      return;
    }

    case types.HIDE_ORDER: {
      draft.result = draft.result.filter((id) => id !== action.payload);
    }
  }
};

const tableOrdersReducer = produce(reducer, defaultState);
export default tableOrdersReducer;
