import io from "socket.io-client";
 

const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
  reconnect: true,
  transports: ["websocket", "polling"],
  secure: true,
});

export default socket;
