import * as types from "../types";
// import { toast } from "react-toastify";
// import langs from "../../utills/langs";

export default function activeTablesReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ACTIVE_TABLES:
      return payload;
    case types.ADD_ACTIVE_TABLE: {
      // Check if order already exists
      const tableNums = state.map((o) => o.tableNum);
      const orderExists = tableNums.includes(+payload.tableNum);
      let updatedState = [];
      if (orderExists) {
        // Change table status and paid status if table exists
        const { tableNum, paid, createdAt } = payload;
        updatedState = state.map((o) => {
          const order = o;
          if (+order.tableNum === +tableNum) {
            order.paid = paid;
            order.status = "PENDING";
            order.createdAt = createdAt;
          }
          return order;
        });
      } else {
        // Add new table
        updatedState = [...state, payload];
      }

      const sortedStaet = updatedState.sort((o) =>
        o.status === "PENDING" ? -1 : 1
      );
      return sortedStaet;
    }
    case "CHANGE_TABLE_STATUS": {
      const { status, tableNum } = payload;
      const updatedState = state.map((order) => {
        const _order = order;
        if (+_order.tableNum === +tableNum) {
          _order.status = status;
        }
        return _order;
      });

      const sortedStaet = updatedState.sort((o) =>
        o.status === "PENDING" ? -1 : 1
      );
      return sortedStaet;
    }

    default:
      return state;
  }
}
