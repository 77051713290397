import { schema } from "normalizr";

export const orderItem = new schema.Entity(
	"orderItems",
	{},
	{ idAttribute: "orderDetailId" }
);

export const tableOrder = new schema.Entity("tableOrders", {
	orderItems: [orderItem]
});
