// @ts-check
/**
 * @typedef {import("react-redux").ConnectedProps<typeof connector>} ConnectedProps
 */

import { useOnlineState } from "beautiful-react-hooks";
import * as React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import OnlineSnackbar from "./components/OnlineSnackbar";
import useMovingTitle from "./hooks/useMovingTitle";
import useToken from "./hooks/useToken";
import useAudioLoop from "./hooks/useAudioLoop";
import AdminLayout from "./layouts/AdminLayout";
import PrivacyLayout from "./layouts/PrivacyLayout";
import AppLayout from "./layouts/AppLayout";
import LandingLayout from "./layouts/LandingLayout";
import OrderLayout from "./layouts/OrderLayout";
import SuspendLayout from "./layouts/SuspendLayout";
import LandingLogin from "./pages/LandingLogin";
import LandingRegistration from "./pages/LandingRegistration";
import * as userActions from "./store/actions/userActions";
import langs from "./utills/langs";
import "react-tabs/style/react-tabs.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./Pages.css";

const PrivacyGe = React.lazy(() => import("./pages/PrivacyGe"));
const PrivacyEn = React.lazy(() => import("./pages/PrivacyEn"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Orders = React.lazy(() => import("./pages/Orders"));
const Reservations = React.lazy(() => import("./pages/Reservations"));
const Languages = React.lazy(() => import("./pages/Languages"));
const Reports = React.lazy(() => import("./pages/Reports"));
const Billing = React.lazy(() => import("./pages/Billing"));
const CustomerStats = React.lazy(() => import("./pages/CustomerStats"));
const AddTable = React.lazy(() => import("./pages/AddTable"));
const GenerateTables = React.lazy(() => import("./pages/GenerateTables"));
const Tables = React.lazy(() => import("./pages/Tables"));
const DeliveryOrders = React.lazy(() => import("./pages/DeliveryOrders"));
const TableOrders = React.lazy(() => import("./pages/TableOrders"));
const DriveThruOrders = React.lazy(() => import("./pages/DriveThruOrders"));
const Categories = React.lazy(() => import("./pages/Categories"));
const Food = React.lazy(() => import("./pages/Food"));
const Setup = React.lazy(() => import("./pages/Setup"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

Modal.setAppElement("#root");

/** @param {ConnectedProps} props */
function App({ validateToken }) {
  const token = useToken();

  // when token changes, user is automatically logged in from here
  React.useEffect(() => {
    if (token) {
      validateToken(token).catch((err) => {
        console.log("Token verification failed", err);
      });
    }
  }, [validateToken, token]);

  // initialize languages
  React.useEffect(() => {
    langs.setLocale(); // override

   
  }, []);

  // // Auto refresh in 1 hour
  // React.useEffect(() => {
  //   setInterval(() => {
  //     window.location.reload()
  //   }, 3600000);
  // }, [])

  useAudioLoop();

  useMovingTitle();

  const isOnline = useOnlineState();

  return (
    <>
      <ToastContainer autoClose={5000} />
      {!isOnline && <OnlineSnackbar />}

      <Routes>
        {/* Suspend all routes */}
        <Route element={<SuspendLayout />}>
          {/* 404 page  */}
          <Route path="/*" element={<NotFound />} />

          {/* independant routes */}
          <Route path="privacy" element={<PrivacyLayout />}>
            <Route index element={<PrivacyGe />} />
            <Route path="ka" element={<PrivacyGe />} />
            <Route path="en" element={<PrivacyEn />} />
          </Route>
          <Route path="contact" element={<Contact />} />

          {/* Logged-out routes */}
          <Route path="/" element={<LandingLayout />}>
            <Route path="login" element={<LandingLogin />} />
            <Route path="register" element={<LandingRegistration />} />
          </Route>

          {/* Authorized routes */}
          <Route element={<AppLayout />}>
            {/* Orders flow */}
            <Route path="orders" element={<Orders />} />
            <Route element={<OrderLayout />}>
              <Route
                path="orders/delivery/:deviceId"
                element={<DeliveryOrders />}
              />
              <Route
                path="orders/drive-thru/:deviceId"
                element={<DriveThruOrders />}
              />
              <Route
                path="orders/table/:zoneNum/:tableNum"
                element={<TableOrders />}
              />
            </Route>

            <Route path="reservations" element={<Reservations />} />
            <Route path="languages" element={<Languages />} />
            <Route path="tables" element={<Tables />} />
            <Route path="reports" element={<Reports />} />
            <Route path="bills" element={<Billing />} />
            <Route path="customer-stats" element={<CustomerStats />} />
            <Route path="addtable" element={<AddTable />} />
            <Route path="generatetables" element={<GenerateTables />} />

            {/* admin routes */}
            <Route element={<AdminLayout />}>
              <Route path="categories" element={<Categories />} />
              <Route path="food" element={<Food />} />
              <Route path="setup" element={<Setup />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

const mapDispatchToProps = {
  validateToken: userActions.validateToken,
};

const connector = connect(null, mapDispatchToProps);
export default connector(App);
