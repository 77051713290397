import { schema } from 'normalizr';

export const orderItem = new schema.Entity(
  'orderItems',
  {},
  { idAttribute: 'orderDetailId' }
);

export const deliveryOrder = new schema.Entity('deliveryOrder', {
  orderItems: [orderItem],
});
