import * as types from "../types";
// import { toast } from "react-toastify";
// import langs from "../../utills/langs";

const initialState = {
  page: 1,
  reservations: [],
};

export default function outdatedReservationReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case types.OUTDATED_RESERVATIONS_GET:
      return {
        page: state.page + action.payload.pageInc,
        reservations: action.payload.reservations,
      };
    default:
      return state;
  }
}
