const keywords = {
  en: {
    add: "Add",
    address: "Address",
    call: "Call",
    calorie: "Calories",
    position: "position",
    cancel: "Cancel",
    currency: "Currency",
    day: "Day",
    edit: "Edit",
    entity_name: "Entity name",
    entity_type: "Entity type",
    entry_exists: "Such entry already exists",
    fixed: "Fixed",
    from: "from",
    hotel: "Hotel",
    hour: "Hour",
    image_uploaded: "Image uploaded",
    invalid_arguments: "Check input values",
    login: "Login",
    logout: "Logout",
    logout_from_wenu: "Logout from wenu?",
    menu: "Menu",
    opening_hours: "Opening hours",
    password: "Password",
    percentage: "Percentage",
    phone: "Phone",
    price: "Price",
    primary: "Primary",
    publish: "Publish",
    published: "Published",
    rate: "Rate",
    service_fee_rate: "Service Fee Rate",
    delivery_price: "Delivery Price: ",
    drive_thru_price: "Drive-Thru Price: ",
    reports: "Reports",
    reservation: "Reservation",
    restaurant: "Restaurant",
    save: "Save",
    setup: "SETUP",
    submit: "Submit",
    timezone: "Timezone",
    to: "to",
    toggle_functionality: "Toggle functionality",
    under_development: "UNDER DEVELOPMENT",
    unpublish: "Unpublish",
    username_or_email: "Username or email",
    out_of_stock: "Out of Stock",
    registration: "Registration",
    username: "Username",
    registration_done: "Registration has been completed!",
    unpublished: "Unpublished",

    // category:
    add_category: "Add category",
    category: "Category",
    category_added: "Category added",
    category_updated: "Category updated",
    category_published: "Category published",
    category_unpublished: "Category unpublished",
    category_removed: "Category removed",
    categories: "Categories",
    choose_category: "Choose category",
    my_categories: "My Categories",

    // language:
    add_language: "Add language",
    choose_language: "Choose language",
    language_added: "Language added",
    language_updated: "Language updated",
    language_published: "Language published",
    language_unpublished: "Language unpublished",
    language_removed: "Language removed",
    languages: "Languages",
    my_languages: "MY LANGUAGES",

    // food:
    add_food: "Add food",
    food: "FOOD",
    food_image_removed: "Food image removed",
    food_description: "Food description",
    food_name: "Food name",
    food_added: "Food added",
    food_updated: "Food updated",
    food_published: "Food published",
    food_unpublished: "Food unpublished",
    food_removed: "Food removed",
    my_food: "MY FOOD",

    // zone:
    add_zone: "Add zone",
    choose_zone: "Choose zone",
    my_zones: "MY ZONES",
    remove_zone: "Remove zone",
    zones: "ZONES",
    zone_name: "Zone name",
    zone_added: "Zone added",
    zone_num: "Zone number",
    zone_updated: "Zone updated",
    zone_removed: "Zone removed",

    // table:
    add_table: "Add table",
    my_tables: "MY TABLES",
    tables: "TABLES",
    table_name: "Table name",
    table_added: "Table added",
    table_num: "Table number",
    table_updated: "Table updated",
    table_removed: "Table removed",

    // order:
    orders: "Orders",
    no_orders: "NO ORDERS",

    // edit-order
    reset_table: "RESET TABLE",
    reset_orders: "RESET ORDERS",
    close_orders: "CLOSE ORDERS",
    done: "DONE",
    accepted: "ACCEPTED",
    pending: "PENDING",
    sum_ex_fee: "SUM:",
    fee: "Fee",
    total: "TOTAL",
    orders_at_the_table: "Orders at the table",
    drive_thru_order_from: "Drive thru order from: ",
    // delivery_order: "Delivery order: ",

    // reports
    id: "Id",
    time: "Time",
    "table 0": "Table\xa00",
    status: "Status",
    rejected: "Rejected",
    comment: "Comment",
    customer: "Customer",

    // needs to be categorised
    accrued_revenue_from_delivery_orders:
      "Accrued Revenue From Delivery Orders",
    accrued_revenue_from_drive_thru_orders:
      "Accrued Revenue From Drive Thru Orders",
    accrued_revenue_from_table_orders: "Accrued Revenue From Table Orders",
    accrued_revenue_from_tips: "Accrued Revenue From Tips",
    accumulated_payment_from_wenu: "Accumulated Payment From WENU: ",
    add_bank_account: "Add Bank Account",
    add_new_category: "Add New Category",
    add_new_dish: "Add New Dish",
    add_wenu_payment: "Add Wenu Payment",
    allow_orders: "Allow Orders",
    bank_accounts: "Bank accounts: ",
    bills: "BILLS",
    calories: "Calories",
    change_password: "Change Password",
    current_password: "Current Password",
    customer_stats: "CUSTOMER STATS",
    delivery: "Delivery",
    delivery_order_from: "Delivery Order From: ",
    delivery_radius: "Delivery Radius: ",
    drive_thru: "Drive Thru",
    drive_thru_order: "WENU Drive",
    drive_thru_radius: "Drive Thru Radius: ",
    friday: "Friday",
    monday: "Monday",
    new_password: "New Password",
    not_paid: "Not Paid",
    paid: "Paid",
    partially_paid: "Partially Paid",
    payment_received_from_wenu: "Payment Received From WENU",
    pending_receivable_from_wenu: "Pending Receivable from WENU",
    perparation_time: "Perparation Time",
    quantity: "Quantity",
    repeat: "Repeat",
    restaurant_revenue: "Restaurant revenue",
    saturday: "Saturday",
    sunday: "Sunday",
    table_order: "Table Order",
    thursday: "Thursday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    wenu_comission: "WENU Comission",
  },
  ka: {
    add: "დამატება",
    address: "მისამართი",
    call: "ზარი",
    calorie: "კალორიები",
    position: "ადგილმდებარეობა",
    cancel: "გაუქმება",
    currency: "ვალუტა",
    day: "დღე",
    edit: "შეცვლა",
    entity_name: "ორგანიზაციის სახელი",
    entity_type: "ორგანიზაციის ტიპი",
    entry_exists: "მსგავსი ჩანაწერი უკვე არსებობს",
    fixed: "ფიქსირებული",
    from: "დან",
    hotel: "სასტუმრო",
    hour: "საათი",
    image_uploaded: "სურათი აიტვირთა",
    invalid_arguments: "გადაამოწმეთ შეყვანილი ველები",
    login: "შესვლა",
    logout: "გამოსვლა",
    logout_from_wenu: "გსურთ ვენიუდან გამოსვლა?",
    menu: "მენიუ",
    opening_hours: "სამუშაო საათები",
    percentage: "პროცენტული",
    phone: "ტელეფონი",
    price: "ფასი",
    password: "პაროლი",
    primary: "მთავარი",
    publish: "გამოქვეყნება",
    published: "გამოქვეყნებული",
    rate: "ტარიფი",
    service_fee_rate: "მომსახურების ტარიფი",
    delivery_price: "მიტანის სერვისის საფასური: ",
    drive_thru_price: "დრავი-სრუს საფასური: ",
    reports: "რეპორტები",
    reservation: "ჯავშანი",
    restaurant: "რესტორანი",
    save: "შენახვა",
    setup: "კონფიგურაცია",
    submit: "გაგზავნა",
    to: "მდე",
    timezone: "სასაათო სარტყელი",
    toggle_functionality: "ფუნქციების გააქტიურება",
    under_development: "დამუშავების პროცესში",
    unpublish: "გამოქვეყნებიდან მოხსნა",
    username_or_email: "მომხმარებლის სახელი ან ელ.ფოსტა",
    out_of_stock: "Out of stock",
    registration: "რეგისტრაცია",
    username: "სახელი",
    registration_done: "რეგისტრაცია დასრულებულია!",
    unpublished: "გამოუქვეყნებელი",

    // category:
    add_category: "კატეგორიის დამატება",
    category: "კატეგორია",
    categories: "კატეგორიები",
    category_added: "კატეგორია დაემატა",
    category_updated: "კატეგორია განახლდა",
    category_published: "კატეგორია გამოქვეყნდა",
    category_unpublished: "კატეგორია მოიხსნა გამოქვეყნებიდან",
    category_removed: "კატეგორია წაიშალა",
    choose_category: "აირჩიეთ კატეგორია",
    my_categories: "ჩემი კატეგორიები",

    // food:
    add_food: "კერძის დამატება",
    food: "კერძები",
    food_image_removed: "სურათი წაიშალა",
    food_name: "კერძის სახელი",
    food_description: "კერძის აღწერა",
    food_added: "კერძი დაემატა",
    food_updated: "კერძი განახლდა",
    food_published: "კერძი გამოქვეყნდა",
    food_unpublished: "კერძი მოიხსნა გამოქვეყნებიდან",
    food_removed: "კერძი წაიშალა",
    my_food: "ჩემი კერძები",

    // language:
    add_language: "ენის დამატება",
    choose_language: "აირჩიეთ ენა",
    language_added: "ენა დაემატა",
    language_updated: "ენა განახლდა",
    language_published: "ენა გამოქვეყნდა",
    language_unpublished: "ენა მოიხსნა გამოქვეყნებიდან",
    language_removed: "ენა წაიშალა",
    languages: "ენები",
    my_languages: "ჩემი ენები",

    // zone:
    add_zone: "ზონის დამატება",
    choose_zone: "აირჩიეთ ზონა",
    my_zones: "ჩემი ზონები",
    remove_zone: "ზონის წაშლა",
    zones: "ზონები",
    zone_name: "ზონის სახელი",
    zone_num: "ზონის ნომერი",
    zone_added: "ზონა დაემატა",
    zone_updated: "ზონა განახლდა",
    zone_removed: "ზონა წაიშალა",

    // table:
    add_table: "მაგიდის დამატება",
    my_tables: "ჩემი მაგიდები",
    tables: "მაგიდები",
    table_name: "მაგიდის სახელი",
    table_added: "მაგიდა დაემატა",
    table_num: "მაგიდის ნომერი",
    table_updated: "მაგიდა განახლდა",
    table_removed: "მაგიდა წაიშალა",

    // order:
    orders: "შეკვეთები",
    no_orders: "შეკვეთა არაა",

    // edit-order:
    reset_table: "გასუფთავება",
    reset_orders: "გასუფთავება",
    close_orders: "გასუფთავება",
    done: "ოკ",
    accepted: "მიღებული",
    pending: "მოლოდინში",
    sum_ex_fee: "ჯამი:",
    fee: "საკომისიო",
    total: "სრული ჯამი",
    orders_at_the_table: "შეკვეთები მაგიდაზე",
    drive_thru_order_from: "დრაივ-სრუ შეკვეთა: ",
    // delivery_order: "შეკვეთა: ",

    // reports
    id: "ნომერი",
    time: "დრო",
    "table 0": "მაგიდა\xa00",
    status: "სტატუსი",
    rejected: "უარყოფილი",
    comment: "კომენტარი",
    customer: "მომხმარებელი",
  },
  ru: {
    add: "Добавить",
    address: "Адрес",
    call: "Звонок",
    calorie: "Калории",
    position: "позиция",
    cancel: "Отменить",
    currency: "Валюта",
    day: "День",
    edit: "Редактировать",
    entity_name: "Название организации",
    entity_type: "Тип организации",
    entry_exists: "Такая запись уже существует",
    fixed: "Фиксированная",
    from: "от",
    image_uploaded: "Картинка закачена",
    invalid_arguments: "Проверьте вводимые значения",
    hotel: "Отель",
    hour: "Час",
    login: "Зайти",
    logout: "Выйти",
    logout_from_wenu: "Выйти из WENU?",
    menu: "Меню",
    opening_hours: "Рабочее время",
    password: "Пароль",
    percentage: "Процентная",
    phone: "Телефон",
    price: "Цена",
    primary: "Примарный",
    publish: "Опубликовать",
    published: "Опубликованный",
    rate: "ставка",
    reports: "Репорты",
    reservation: "Бронирование",
    restaurant: "Ресторан",
    save: "Сохранить",
    setup: "Настройки",
    submit: "Отправить",
    timezone: "Временная зона",
    to: "до",
    toggle_functionality: "Включить функции",
    under_development: "В процесе разработки",
    unpublish: "Снять с опубликования",
    username_or_email: "Имя пользователя или эл.почта",
    out_of_stock: "Out of stock",
    registration: "Регистрация",
    username: "Имя пользователя",
    registration_done: "Регистрация завершена!",
    unpublished: "Отменить публикацию",

    // category:
    add_category: "Добавить категорию",
    category: "Категория",
    categories: "Категории",
    category_added: "Категория добавилась",
    category_updated: "Категория обновилась",
    category_published: "Категория опубликовалась",
    category_unpublished: "Категория снята с опубликования",
    category_removed: "Категория удалилась",
    choose_category: "Выберите категорию",
    my_categories: "Мои категории",

    // food:
    add_food: "Добавить еду",
    food: "Еда",
    food_description: "Описание еды",
    food_image_removed: "Картинка удалена",
    food_name: "Название еды",
    food_added: "Еда добавилась",
    food_updated: "Еда обновилась",
    food_published: "Еда опубликовалась",
    food_unpublished: "Еда снята с опубликования",
    food_removed: "Еда удалилась",
    my_food: "Moя еда",

    // language:
    add_language: "Добавить язык",
    choose_language: "Выберите язык",
    language_added: "Язык добавился",
    language_updated: "Язык обновился",
    language_published: "Язык опубликовался",
    language_unpublished: "Язык снят с опубликования",
    language_removed: "Язык удалился",
    languages: "Языки",
    my_languages: "Мои языки",

    // zone:
    add_zone: "Добавить зону",
    choose_zone: "Выберите зону",
    my_zones: "Мои зоны",
    remove_zone: "Удалить зону",
    zones: "Зоны",
    zone_name: "Название зоны",
    zone_num: "Номер зоны",
    zone_added: "Зона добавилась",
    zone_updated: "Зона обнавилась",
    zone_removed: "Зона удалилась",

    // table:
    add_table: "Добавить стол",
    my_tables: "Мои столы",
    tables: "Столы",
    table_name: "Название стола",
    table_added: "Стол добавился",
    table_num: "Номер стола",
    table_updated: "Стол обновился",
    table_removed: "Стол удалился",

    // order:
    orders: "Заказы",
    no_orders: "Нет заказов",

    // edit-order
    reset_table: "СБРОС",
    done: "ХОРОШО",
    accepted: "ПРИНЯТО",
    pending: "ОЖИДАНИЯ",
    sum_ex_fee: "СУММА:",
    fee: "СБОР",
    total: "ВСЕГО",
    orders_at_the_table: "Заказы",

    // reports
    id: "ID",
    "table 0": "ТАБЛИЦА\xa00",
    rejected: "ОТКЛОНЕНО",
    comment: "КОММЕНТАРИЙ",
    customer: "ГОСТЬ",
    status: "СТАТУС",
    time: "ВРЕМЯ",
  },
};

export default keywords;
