/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

/*
  
TODO: Refactor!!

this component is most prominent in the app and least accessible.
had to disable accessibility lints for this file.

we must use semantic html in the refactord version

*/

import classNames from "classnames";
import React from "react";
import { Icon } from "react-icons-kit";
import { angleDown } from "react-icons-kit/fa/angleDown";
import { angleRight } from "react-icons-kit/fa/angleRight";
import { areaChart } from "react-icons-kit/fa/areaChart";
import { bars } from "react-icons-kit/fa/bars";
import { money } from "react-icons-kit/fa/money";
import { group } from "react-icons-kit/fa/group";
import { book } from "react-icons-kit/fa/book";
import { coffee } from "react-icons-kit/fa/coffee";
import { cog } from "react-icons-kit/fa/cog";
import { flag } from "react-icons-kit/fa/flag";
import { qrcode } from "react-icons-kit/fa/qrcode";
import { signOut } from "react-icons-kit/fa/signOut";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as pendingCounterActions from "../store/actions/pendingCounterActions";
import * as userActions from "../store/actions/userActions";
import langs, { KEYS as langKeys } from "../utills/langs";
import LogOutModal from "./mainMenu/LogOutModal";
import * as storage from "../providers/storage";
import AdminSelector from "./mainMenu/AdminSelector";

import "./MainMenu.css";

/**
 *
 * @param {import("react-redux").ConnectedProps<typeof connector>} props
 */

function MainMenu({
  getPendingOrders,
  getPendingReservations,
  getPendingBills,
  logout,
  changeLang,
  pendingOrders,
  pendingReservations,
  pendingBills,
  unseenCalls,
  allowReservation,
  allowOrders,
  allowDriveThru,
  allowDelivery,
  isAdmin,
  isAdminRestaurant,
  user,
}) {
  const [langOptions, setLangOptions] = React.useState(false);
  const [submenu, setSubmenu] = React.useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);
  const [isWebView, setIsWebView] = React.useState(false);

  React.useEffect(() => {
    getPendingOrders();
    getPendingReservations();
    getPendingBills();
  }, [getPendingBills, getPendingOrders, getPendingReservations]);

  React.useEffect(() => {
    setIsWebView(storage.get("isWebview"));
  }, []);

  const navigate = useNavigate();
  const _logout = () => {
    logout();
    navigate("/", { replace: true });
  };

  const toggleSubmenu = (e) => {
    e.preventDefault();
    setSubmenu(!submenu);
  };

  const setLanguage = (el) => {
    if (el) {
      langs.setLocale(el);
    }
    setLangOptions(!langOptions);
    changeLang(el);
  };

  const { allowPostPayment, allowPrePayment } = user || {};
  const isPaymentAllowed = allowPrePayment === 1 || allowPostPayment === 1;

  const { pathname } = useLocation();
  const orderClassName = classNames({
    active: pathname === "/orders",
    "order-unseen": pendingOrders > 0,
    "bill-unseen": pendingBills > 0,
    "call-unseen": unseenCalls,
  });

  const reservationClassName = classNames({
    active: pathname === "/reservations",
    "reservation-unseen": pendingReservations > 0,
  });

  return (
    <>
      <div className="mainMenu top-menu noselect">
        <div className="main-menu-container">
          <a
            href="/"
            data-name="hamburgerMenu"
            onClick={toggleSubmenu}
            title={langs.translate("menu")}
          >
            <figure>
              <Icon size={18} icon={bars} />
            </figure>
          </a>
          {(!!allowOrders ||
            !!allowDriveThru ||
            !!allowDelivery ||
            allowPrePayment > 0) && (
            <Link className={orderClassName} name="orders" to="/orders">
              <figure>
                {langs.translate("orders")}
                {(pendingOrders > 0 || pendingBills > 0) && (
                  <div className="knobMarker">
                    <div>{pendingOrders + pendingBills}</div>
                  </div>
                )}
              </figure>
            </Link>
          )}
          {!!allowReservation && (
            <Link
              className={reservationClassName}
              data-name="reservations"
              to="/reservations"
            >
              <figure>
                {langs.translate("reservation")}
                {pendingReservations > 0 && (
                  <div className="knobMarker">
                    <div>{pendingReservations}</div>
                  </div>
                )}
              </figure>
            </Link>
          )}
          {storage.get("wenuAdminToken") && <AdminSelector />}
          <a onClick={(e) => e.preventDefault()} href="/" data-name="lang">
            <figure onClick={() => setLanguage(null)}>
              {langs.getSelected().toUpperCase()}
              &nbsp;
              <Icon size={18} icon={angleDown} />
            </figure>
            {langOptions && (
              <div
                onMouseLeave={() => setLanguage(null)}
                className="langOptions"
              >
                <ul>
                  {/* show unselected languages */}
                  {langKeys
                    .filter((el) => el !== langs.getSelected())
                    .map((el) => (
                      <li onClick={() => setLanguage(el)} key={el}>
                        {el.toUpperCase()}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </a>

          {!isWebView && (
            <a
              href="/"
              onClick={(e) => e.preventDefault()}
              data-name="logout"
              title={langs.translate("logout")}
            >
              <figure onClick={() => setLogoutModalOpen(true)}>
                <Icon size={18} icon={signOut} />
              </figure>
            </a>
          )}
        </div>
      </div>
      {/* ------------------------------ submenu ----------------------------- */}
      {submenu && (
        <div
          onMouseLeave={toggleSubmenu}
          onClick={toggleSubmenu}
          className="mainMenu subMenu noselect"
        >
          <div className="sub-menu-container">
            {isAdmin && (
              <Link
                data-name="setup"
                to="/setup"
                title={langs.translate("setup")}
              >
                <div>
                  <Icon size={18} icon={cog} />
                  &nbsp;
                  {langs.translate("setup")}
                </div>
                <Icon size={18} icon={angleRight} />
              </Link>
            )}
            {isAdmin && (
              <Link
                data-name="language"
                to="/languages"
                title={langs.translate("languages")}
              >
                <div>
                  <Icon size={18} icon={flag} />
                  &nbsp;
                  {langs.translate("languages")}
                </div>
                <Icon size={18} icon={angleRight} />
              </Link>
            )}
            {isAdmin && (
              <Link
                data-name="category"
                to="/categories"
                title={langs.translate("categories")}
              >
                <div>
                  <Icon size={18} icon={book} />
                  &nbsp;
                  {langs.translate("categories")}
                </div>
                <Icon size={18} icon={angleRight} />
              </Link>
            )}
            {isAdmin && (
              <Link data-name="food" to="/food" title={langs.translate("food")}>
                <div>
                  <Icon size={18} icon={coffee} />
                  &nbsp;
                  {langs.translate("food")}
                </div>
                <Icon size={18} icon={angleRight} />
              </Link>
            )}
            {isAdmin && (
              <Link
                data-name="table"
                to="/tables"
                title={langs.translate("tables")}
              >
                <div>
                  <Icon size={18} icon={qrcode} />
                  &nbsp;
                  {langs.translate("tables")}
                </div>
                <Icon size={18} icon={angleRight} />
              </Link>
            )}
            <Link
              data-name="report"
              to="/reports"
              title={langs.translate("reports")}
            >
              <div>
                <Icon size={18} icon={areaChart} />
                &nbsp;
                {langs.translate("reports")}
              </div>
              <Icon size={18} icon={angleRight} />
            </Link>
            {isPaymentAllowed && (
              <Link data-name="bill" to="/bills" title="Bills">
                <div>
                  <Icon size={18} icon={money} />
                  &nbsp; Bills
                </div>
                <Icon size={18} icon={angleRight} />
              </Link>
            )}
            <Link
              data-name="customer-stats"
              to="/customer-stats"
              title="Customer stats"
            >
              <div>
                <Icon size={18} icon={group} />
                &nbsp; Customer stats
              </div>
              <Icon size={18} icon={angleRight} />
            </Link>
            {isAdminRestaurant && (
              <Link data-name="Add Table" to="/addtable" title="Add Table">
                <div>&nbsp; Add Table</div>
                <Icon size={18} icon={angleRight} />
              </Link>
            )}
            {isAdminRestaurant && (
              <Link
                data-name="Add Table"
                to="/generatetables"
                title="Generate Tables"
              >
                <div>&nbsp; Generate Tables</div>
                <Icon size={18} icon={angleRight} />
              </Link>
            )}
          </div>
        </div>
      )}
      <LogOutModal
        isOpen={logoutModalOpen}
        shouldCloseOnOverlayClick
        onRequestClose={() => setLogoutModalOpen(false)}
        onAccept={_logout}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  pendingOrders: state.pendingCounterReducer.orders,
  pendingBills: state.pendingCounterReducer.bills,
  pendingReservations: state.pendingCounterReducer.reservations,
  // unseenOrders: state.unseenReducer.orders,
  unseenCalls: state.unseenReducer.calls,
  // unseenBills: state.unseenReducer.bills,
  // unseenReservations: state.unseenReducer.reservations,
  allowReservation: state.userReducer.user
    ? state.userReducer.user.allowReservation
    : 0,
  allowOrders: state.userReducer.user ? state.userReducer.user.allowOrders : 0,
  allowDriveThru: state.userReducer.user
    ? state.userReducer.user.allowDriveThru
    : 0,
  allowDelivery: state.userReducer.user
    ? state.userReducer.user.allowDelivery
    : 0,
  user: state.userReducer.user,
  isAdmin: state.userReducer.user?.restaurantUser?.isAdmin,
  // pendingOrders: state.orderReducer.pendingOrders,
  isAdminRestaurant:
    state.userReducer.user && state.userReducer.user.restaurantName === "Admin", // temporary, for add table link only, remove later
});

const mapDispatchToProps = {
  changeLang: userActions.changeLang,
  logout: userActions.logout,
  getPendingOrders: pendingCounterActions.getPendingOrders,
  getPendingReservations: pendingCounterActions.getPendingReservations,
  getPendingBills: pendingCounterActions.getPendingBills,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MainMenu);
