import * as types from "../types";

export default function reportsReducer(state = [], { type, payload }) {
  switch (type) {
    case types.SET_REPORTS:
      return payload;

    default:
      return state;
  }
}
