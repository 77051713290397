/* immer's functions can have these things disabled */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

import { normalize } from "normalizr";
import produce from "immer";
import * as types from "../types";
import * as schema from "../schemas/driveThruOrderSchema";

const initialState = {
  entities: {
    driveThruOrders: {},
    orderItems: {},
  },
  result: [],
};

const driveThruOrdersReducer = (draft, action) => {
  const { driveThruOrders, orderItems } = draft.entities;

  switch (action.type) {
    case types.NORMALIZE_DRIVE_THRU_ORDERS: {
      return normalize(action.payload.orders, [schema.driveThruOrder]);
    }
    case types.CHANGE_DRIVE_THRU_ORDER_STATUS: {
      const { orderId, status } = action.payload;
      driveThruOrders[orderId].status = status;
      return;
    }
    case types.CHANGE_DRIVE_THRU_FOOD_DELETED: {
      const { orderDetailId, deleted } = action.payload;
      orderItems[orderDetailId].deleted = deleted;
      return;
    }
    case types.HIDE_DRIVE_THRU_ORDER: {
      draft.result = draft.result.filter((id) => id !== action.payload);
    }
  }
};

export default produce(driveThruOrdersReducer, initialState);
