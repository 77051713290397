import * as React from "react";
import { Outlet, useLocation, NavLink, useNavigate } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import langs from "../utills/langs";
import "./PrivacyLayout.css";

export default function PrivacyLayout() {
  const location = useLocation();
  const navigate = useNavigate();

  // lang = "ka" | "en"
  const lang = (location.pathname.split("/privacy")[1] || "/ka").substring(1);

  return (
    <div className="privacy-layout">
      <nav className="privacy-links">
        <p id="change-language">{langs.translate("choose_language", lang)}</p>

        <ul role="tablist" aria-describedby="change-language">
          <li role="tab" aria-selected={lang === "en"}>
            <Chip
              onClick={() => {
                navigate("en");
              }}
              label="English"
            />
          </li>
          <li role="tab" aria-selected={lang === "ka"}>
            <Chip
              onClick={() => {
                navigate("ka");
              }}
              label="ქართული"
            />
          </li>
        </ul>
      </nav>

      <Outlet />
    </div>
  );
}
