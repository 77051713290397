import produce from "immer";
import * as types from "../types";

const initialState = {
  token: null,
  user: /** @type {import("../../utills/types").RestaurantSetup} */ (null),
  lang: "en",
  languagesGot: [], // all the languages
  languagesAdded: [], // restaurant's languages
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.USER_LOGIN:
      if (action.payload.error) {
        return {
          ...state,
          user: null,
          token: null,
        };
      }
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.data,
      };

    case types.CHANGE_LANG:
      return { ...state, lang: action.payload.lang };
    case types.LANGUAGES_GET:
      return {
        ...state,
        languagesGot: action.payload.languagesGot,
        languagesAdded: action.payload.languagesAdded,
      };
    case types.LANGUAGE_ADD:
    case types.LANGUAGE_REMOVE:
    case types.LANGUAGE_PUBLISH:
      return (() => {
        const o = {
          ...state,
          languagesAdded: action.payload.languagesAdded,
        };
        return o;
      })(state);
    case types.LANGUAGE_MAKE_PRIMARY:
      return (() => {
        if (action.payload.error) return state;
        const o = [...state.languagesAdded]; // get current languages added
        const old = o.find((el) => el.pr === 1);
        if (old) old.pr = 0;
        const el = o.find((_el) => _el.iso === action.payload.iso); // find one we set as primary
        if (el) el.pr = 1; // modify found as primary
        return { ...state, languagesAdded: o };
      })(state);
    case types.USER_LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
      };

    case types.UPDATE_TFA_STATUS:
      return produce(state, (draft) => {
        if (draft.user?.restaurantUser) {
          // eslint-disable-next-line no-param-reassign
          draft.user.restaurantUser.tfa_verified = action.payload;
        }
      });
    default:
      return state;
  }
}
