import * as storage from "../../providers/storage";
import axios from "../../providers/axios";
import * as types from "../types";

export const getPendingOrders = () => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);

    axios
      .get("/api/orders/unseen")
      .then((res) => {
        dispatch({
          type: types.SET_PENDING_ORDERS,
          payload: res.data.unseenCount,
        });
      })
      .catch((err) => {
        console.log("pendingCounterActions::getPendingOrders", err);
        return Promise.reject(err);
      });
  }
};

export const getPendingReservations = () => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);

    axios
      .get("/api/reservations/unseen")
      .then((res) => {
        dispatch({
          type: types.SET_PENDING_RESERVATIONS,
          payload: res.data.unseenCount,
        });
      })
      .catch((err) => {
        console.log("pendingCounterActions::getPendingReservations", err);
        return Promise.reject(err);
      });
  }
};

export const getPendingBills = () => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);

    axios
      .get("/api/bills/unseen")
      .then((res) => {
        dispatch({
          type: types.SET_PENDING_BILLS,
          payload: res.data.unseenCount,
        });
      })
      .catch((err) => {
        console.log("pendingCounterActions::getPendingReservations", err);
        return Promise.reject(err);
      });
  }
};
