import * as types from "../types";

const initialState = {
  reservations: 0,
  orders: 0,
  bills: 0,
};

export default function pendingCounterReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case types.SET_PENDING_RESERVATIONS:
      return { ...state, reservations: payload };
    case types.SET_PENDING_ORDERS:
      return { ...state, orders: payload };
    case types.SET_PENDING_BILLS:
      return { ...state, bills: payload };
    default:
      return state;
  }
}
