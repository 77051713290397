import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import "./SimpleModal.css";

Modal.setAppElement("#root");

/** @param {PropTypes.InferProps<SimpleModal.propTypes> & Modal.Props} props */
function SimpleModal({
  onYes,
  question,
  subtitle,
  yes,
  no,
  className,
  ...modalProps
}) {
  return (
    <Modal
      className={classNames("simpleModal-modal", className)}
      overlayClassName="simpleModal-overlay"
      {...modalProps}
    >
      <div className="simpleModal">
        <h3>{question}</h3>
        {subtitle && <p>{subtitle}</p>}
        <div className="row">
          <button
            className="acc-btn acc-btn--text"
            type="button"
            onClick={onYes}
          >
            {yes || "YES"}
          </button>
          <button
            className="acc-btn acc-btn--text"
            onClick={modalProps.onRequestClose}
            type="button"
          >
            {no || "NO"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

SimpleModal.propTypes = {
  onYes: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  yes: PropTypes.string,
  no: PropTypes.string,
  className: PropTypes.string,
};

export default SimpleModal;
