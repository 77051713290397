import * as types from "../types";
import axios from "../../providers/axios";
import * as storage from "../../providers/storage";

export function setupGet() {
  return (dispath) =>
    axios
      .post("/api/restaurant/setupGet")
      .then((response) => {
        const { data } = response;
        dispath({ type: types.SETUP_GET, payload: data });
      })
      .catch((err) => {
        console.log("setupActions::setupGet", err);
        return Promise.reject(err);
      });
}

export function setupPost(data) {
  return (dispath) => {
    const token = storage.get("token");
    if (!token) {
      return Promise.reject(
        new Error({
          error: "no token",
        })
      );
    }

    axios.setHeader("token", token);
    return axios
      .post("/api/restaurant/setupPost", data)
      .then((response) => {
        if (response.data.error) {
          dispath({
            type: types.SETUP_DONE,
            payload: { errMessage: response.data.errMessage },
          });
        } else {
          dispath({ type: types.SETUP_DONE, payload: { task: "Updated" } });
        }
      })
      .catch((err) => {
        console.log("setupActions::setupPost", err);
        return Promise.reject(err);
      });
  };
}
