/*


  TODO: move driveThruReducer & driveThruOrdersReducer Actions to seperate files


*/
import * as types from "../types";
import axios from "../../providers/axios";
import * as storage from "../../providers/storage";

export const getDriveThruOrders = () => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);
    const url = "/api/driveThru/orders";

    axios
      .get(url)
      .then((response) => {
        const { data } = response;
        dispatch({ type: types.SET_DRIVE_THRU_ORDERS, payload: data });
      })
      .catch((err) => {
        console.log("DRIVE ORDERS::", err);
        return Promise.reject(err);
      });
  }
};

export const driveThruOrdersGet = (plateNum) => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);

    axios
      .get(`/api/driveThru/carOrders/${plateNum}`)
      .then((response) => {
        const { data } = response;
        dispatch({ type: types.NORMALIZE_DRIVE_THRU_ORDERS, payload: data });
      })
      .catch((err) => {
        console.log("driveThruOrdersGet:: ", err);
        return Promise.reject(err);
      });
  }
};

export const resetDriveThruOrders = (deviceId) => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios
      .post(`/api/driveThru/resetDriveThruOrders`, {
        deviceId,
      })
      .then(() => {
        dispatch({ type: types.RESET_DRIVE_THRU, payload: { deviceId } });
        dispatch({ type: "REMOVE_DRIVER_LOCATION", payload: { deviceId } });
      })
      .catch((err) => {
        console.log("RESET CAR::", err);
        return Promise.reject(err);
      });
  }
};

/**
 * @param {Object} params - data to send to the server
 * @param {number} params.orderId
 */
export const changeDriveThruOrderStatus =
  ({ orderId, navigate }) =>
  async (dispatch) => {
    const token = storage.get("token");
    if (token) {
      await axios.post(`/api/orders/${orderId}/updateStatus`, { status: "ACCEPTED" })
        .then(() => {
          console.log("disabled dispatch");
          navigate('/orders')
          // dispatch({
          //   type: types.CHANGE_DRIVE_THRU_ORDER_STATUS,
          //   payload: { orderId, status: "ACCEPTED" },
          // });
        })
        .catch((err) => {
          console.log("driveThruActions::changeDriveThruOrderStatus", err);
          return Promise.reject(err);
        });
    }
  };

/**
 * toggle food
 * @param {Object} param
 * @param {number} param.orderDetailId - unique identifier of orderItem
 * @param {boolean} param.deleted - next state
 */
export const changeDriveThruFoodDeleted =
  ({ orderDetailId, deleted }) =>
  (dispatch) => {
    const token = storage.get("token");
    if (token) {
      axios
        .post(`/api/orderDetails/${orderDetailId}/updateStatus`, {
          status: deleted ? "REJECTED" : "PENDING",
        })
        .then(() => {
          dispatch({
            type: types.CHANGE_DRIVE_THRU_FOOD_DELETED,
            payload: { orderDetailId, deleted },
          });
        })
        .catch((err) => {
          console.log("driveThruActions::changeDriveThruFoodDeleted", err);
          return Promise.reject(err);
        });
    }
  };

export const hideDriveThruOrder = (orderId) => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);
    axios
      .post(`/api/orders/${orderId}/hide`)
      .then(() => {
        dispatch({
          type: types.HIDE_DRIVE_THRU_ORDER,
          payload: orderId,
        });
      })
      .catch((err) => {
        console.log("driveThruActions::hideDriveThruOrder", err);
        return Promise.reject(err);
      });
  }
};
