import React, { useState } from "react";
import Icon from "react-icons-kit";
import { ic_error as ErrorIcon } from "react-icons-kit/md/ic_error";
import { connect } from "react-redux";
import TextField from "../components/TextField";
 
import useValidation from "../hooks/useValidation";
import * as userActions from "../store/actions/userActions";
import "./LandingLogin.scss";

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
const ReCAPTCHA = React.lazy(() => import("react-google-recaptcha"));

const required = (field) => (field.length > 0 ? "" : "Required");

function GlobalError({ children }) {
  return (
    <p
      aria-live="polite"
      style={{
        color: "#ff9100",
        marginTop: 16,
        fontSize: 20,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon size={24} icon={ErrorIcon} style={{ marginRight: 8 }} />
      {children}
    </p>
  );
}

/**
 * @param {import("react-redux").ConnectedProps<typeof connector>} props
 */
function LandingLogin({ login }) {
  // first form
  const [username, setUsername, usernameError] = useValidation("", [required]);
  const [password, setPassword, passwordError] = useValidation("", [required]);
  const [tfaToken, setTfaToken] = useState("");
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const [isServerError, setIsServerError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const _usernameError = setUsername(username, false);
    const _passwordError = setPassword(password, false);
    if (_usernameError || _passwordError || !recaptchaResponse) return;
    try {
      await login({
        username,
        password,
        tfaToken,
        recaptchaResponse,
      });
    } catch (err) {
      setIsServerError(true);
    }
  };

  const onReCaptchaChange = (value) => {
    setRecaptchaResponse(value);
  };

  return (
    <div className="landing-login pattern-dots-md">
      <div className="pattern pattern-dots-md" />

      <form>
        <div>
          <TextField
            error={!!usernameError}
            label="Username*"
            variant="filled"
            style={{ width: "100%", marginBottom: "20px" }}
            value={username}
            helperText={usernameError}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            error={!!passwordError}
            label="Password*"
            type="password"
            variant="filled"
            style={{ width: "100%", marginBottom: "20px" }}
            value={password}
            helperText={passwordError}
            onChange={(e) => setPassword(e.target.value)}
          />

          <TextField
            // error={!!passwordError}
            label="2FA TOKEN (OPTIONAL)"
            variant="filled"
            style={{ width: "100%", marginBottom: "20px" }}
            value={tfaToken}
            // helperText={passwordError}
            onChange={(e) => setTfaToken(e.target.value)}
          />
          <React.Suspense fallback={<div style={{ height: 74 }} />}>
            <ReCAPTCHA sitekey={SITE_KEY} onChange={onReCaptchaChange} />
          </React.Suspense>

          {isServerError && (
            <GlobalError>We weren&apos;t able to Sign you in</GlobalError>
          )}

          <div className="landing-form-bottom">
            <button
              type="submit"
              className="landing-auth-button"
              onClick={handleSubmit}
            >
              Sign in
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapDispatchToProps = {
  login: userActions.login,
};
const connector = connect(null, mapDispatchToProps);
export default connector(LandingLogin);
