/* eslint-disable no-param-reassign */
// @ts-check
import produce from "immer";
import * as types from "../types";
// import { toast } from "react-toastify";
// import langs from "../../utills/langs";

const initialState = {
  disabled: false,
  timezones: [],
  currencies: null,
  restaurant: null,
};

const setupReducer = produce((draft, action) => {
  switch (action.type) {
    case types.SET_DISABLED:
      draft.disabled = true;
      break;
    case types.SETUP_GET: {
      draft.currencies = action.payload.currencies;
      draft.restaurant = action.payload.restaurant;

      /** @type {{timezones: {name: string, timezone: string, tz: string}[]}} */
      const { timezones } = action.payload;

      /** @type {{cities: Set<string>, tz: string}[]} */
      const prettyTimezones = [];
      // group cities in the same timezone into one object & group unique cities
      timezones.forEach((t) => {
        const lastIndex = prettyTimezones.length - 1;
        const city = t.timezone.split("/").slice(-1)[0].replace("_", " ");

        if (prettyTimezones[lastIndex]?.tz === t.tz) {
          prettyTimezones[lastIndex].cities.add(city);
        } else {
          prettyTimezones.push({
            tz: t.tz,
            cities: new Set([city]),
          });
        }
      });

      draft.timezones = prettyTimezones;
      break;
    }
    default:
      break;
  }
}, initialState);

export default setupReducer;
