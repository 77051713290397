// @ts-check
// import { useMediaQuery } from "beautiful-react-hooks";
import className from "classnames";
import React from "react";
import { Outlet, useLocation, Link } from "react-router-dom";
import "./LandingHeader.css";

function LandingHeader() {
  const location = useLocation();
  // const isSmall = useMediaQuery("(max-width: 800px)");

  const authScreen = ["/register", "/login"].includes(location.pathname);

  return (
    <div className="landing-header-wrapper">
      <header
        className={className("landing-header", {
          active: authScreen,
        })}
        style={{
          backgroundImage: `url("/media/images/header.webp")`,
        }}
      >
        <nav>
          <Link to="/">
            <img className="title" src="/media/icons/logo_124.png" alt="wenu" />
          </Link>
          {/* <Link to="register">
          {isSmall ? "Register" : "Register Your Restaurant"}
        </Link> */}
          <Link to="login">Sign in</Link>
        </nav>
        <div className="header-content">
          <img
            className="title"
            src="/media/images/logo.png"
            alt="wenu"
            width={800}
            height={195}
          />
          <h1>Ordering made easy</h1>
          <div className="stores">
            <a
              href="https://itunes.apple.com/us/app/wenu/id1291698669?mt=8"
              className="store store--apple"
              target="_blank"
              rel="noopener noreferrer"
              title="To the App Store"
            >
              <img
                src="/media/images/app-store.png"
                alt="Available on the App Store"
                width={300}
                height={98}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=io.wenu"
              className="store store--google"
              target="_blank"
              rel="noopener noreferrer"
              title="To Google Play Store"
            >
              <img
                src="/media/images/google-play.png"
                alt="Android App on Google Play"
                width={300}
                height={98}
              />
            </a>
          </div>
        </div>
        <div className="auth-overlay">
          {authScreen && (
            <div className="auth-left">
              <div>
                <img
                  className="title"
                  src="/media/images/logo.png"
                  alt="wenu"
                />
                <h2>
                  {location.pathname === "/register" ? "Join Now!" : "Sign in"}
                </h2>
              </div>
              <div className="pattern pattern-dots-md" />
            </div>
          )}
          {/* <TransitionGroup> */}
          {/* <CSSTransition key={location.key} classNames="slide" timeout={1000}> */}
          <Outlet />
          {/* </CSSTransition> */}
          {/* </TransitionGroup> */}
        </div>
      </header>
    </div>
  );
}

export default LandingHeader;
