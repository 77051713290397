/*
	different from pending counter.
 */

import * as types from "../types";

export const addUnseenCalls = () => ({ type: types.ADD_UNSEEN_CALL });

export const removeUnseenCalls = () => ({ type: types.REMOVE_UNSEEN_CALL });

export const addUnseenReservation = (id) => ({
  type: types.ADD_UNSEEN_RESERVATION,
  payload: id,
});

export const removeUnseenReservations = (ids) => ({
  type: types.REMOVE_UNSEEN_RESERVATIONS,
  payload: ids,
});
