// @ts-check

import * as React from "react";
import { usePreviousValue } from "beautiful-react-hooks";
import { useSelector } from "react-redux";
import MovingTitle from "../utills/MovingTitle";

/*

TODO: Check if logic is right and reactive

*/

export default function useMovingTitle() {
  const pendingOrders = useSelector(
    (/** @type {any} */ state) =>
      state.activeTablesReducer.filter((t) => t.status === "PENDING").length
  );
  // display moving title
  const prevPendingOrders = usePreviousValue(pendingOrders);
  React.useEffect(() => {
    const movingTitle = new MovingTitle("________NEW_ORDER", 300, 10);

    if (prevPendingOrders === 0 && pendingOrders > 0) {
      movingTitle.init();
    } else if (prevPendingOrders > 0 && pendingOrders === 0) {
      movingTitle.finish();
    }
  }, [pendingOrders, prevPendingOrders]);
}
