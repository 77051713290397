import * as React from "react";
import { Outlet } from "react-router-dom";
import FullPageLoader from "../components/FullPageLoader";

export default function SuspendLayout() {
  return (
    <React.Suspense fallback={<FullPageLoader />}>
      <Outlet />
    </React.Suspense>
  );
}
