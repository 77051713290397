import * as types from "../types";
import axios from "../../providers/axios";
import * as storage from "../../providers/storage";

/**
 * Fetch active reservations for current customer
 */
// export function activeReservationsForRestaurantGet(lastId) {
// 	return (dispath, getState) => {
// 		const token = storage.get("token");
// 		if (token) {
// 			const unixTs = Math.round(new Date().getTime() / 1000);
// 			axios.setHeader("token", token);
// 			return axios
// 				.get(
// 					`/api/reservations/${unixTs}/outdated${lastId ? "/" + lastId : ""}`
// 				)
// 				.then(response => {
// 					const reservations = response.data;
// 					dispath({
// 						type: types.ACTIVE_RESERVATIONS_GET,
// 						payload: {
//               reservations
//             }
// 					});
// 				});
// 		}
// 	};
// }

/**
 * Accept reservations locally
 * @param {array} reservationIds - Array of reservation ids
 */
export const acceptReservationsInStore = (reservationIds) => ({
  type: types.RESERVATIONS_ACCEPT,
  payload: {
    ids: reservationIds,
    task: "Accepted Reservations",
  },
});

/**
 * Decline reservations locally
 * @param {array} reservationIds - Array of reservation ids
 */
export const declineReservationsInStore = (reservationIds) => ({
  type: types.RESERVATIONS_DECLINE,
  payload: {
    ids: reservationIds,
    task: "Declined Reservations",
  },
});

/**
 * Cancel reservations locally
 * @param {array} reservationIds - Array of reservation ids
 */
export const cancelReservationsInStore = (reservationIds) => ({
  type: types.RESERVATIONS_CANCEL,
  payload: {
    ids: reservationIds,
    task: "Cancelled Reservations",
  },
});

/**
 * assigns active reservations to an empty array
 */
export const clearActiveReservations = () => ({
  type: types.ACTIVE_RESERVATIONS_CLEAR,
});

export const outdatedReservationsGet = (pageInc) => (dispatch, getState) => {
  const token = storage.get("token");
  if (!token) return;
  const { page, reservations } = getState().outdatedReservationReducer;
  const lastId =
    reservations.length > 0 &&
    (page + pageInc) * 20 + reservations.slice(-1)[0].id;

  axios.setHeader("token", token);
  axios
    .get(
      `/api/reservations/${+new Date()}/outdated${lastId ? `/${lastId}` : ""}`
    )
    .then((response) => {
      const _reservations = response.data;
      dispatch({
        type: types.OUTDATED_RESERVATIONS_GET,
        payload: {
          reservations: _reservations,
          pageInc,
        },
      });
    })
    .catch((err) => {
      console.log("reservationActions::outdatedReservationsGet", err);
      return Promise.reject(err);
    });
};

export const activeReservationsGet = () => (dispatch) => {
  const token = storage.get("token");
  if (!token) return;

  axios
    .get(`/api/reservations/${+new Date()}/valid`)
    .then((response) => {
      dispatch({
        type: types.ACTIVE_RESERVATIONS_GET,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log("reservationActions::activeReservationsGet", err);
      return Promise.reject(err);
    });
};

/**
 * change reservation status of selected items with "ACCEPTED"
 * @param {[number]} reservationIds - array of selected ids
 */
export const acceptReservations = (reservationIds) => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);
    axios
      .post(`/api/reservations/accept`, { reservationIds })
      .then(() => {
        dispatch(acceptReservationsInStore(reservationIds));
      })
      .catch((err) => {
        console.log("reservationActions::acceptReservations", err);
        return Promise.reject(err);
      });
  }
};

/**
 * Sends nudes
 * @param {[number]} reservationIds - array of Reservation id
 */
export const declineReservations = (reservationIds, message) => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);
    axios
      .post(`/api/reservations/decline`, { reservationIds, message })
      .then(() => {
        dispatch(declineReservationsInStore(reservationIds));
      })
      .catch((err) => {
        console.log("reservationActions::declineReservations", err);
        return Promise.reject(err);
      });
  }
};
