/* immer's functions can have these things disabled */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { normalize } from "normalizr";
import produce from "immer";
import * as schema from "../schemas/deliveryOrderSchema";
import * as types from "../types";

const initialState = {
  entities: {
    deliveryOrder: {},
    orderItems: {},
  },
  result: null,
};

const deliveryOrdersReducer = (draft, action) => {
  const { deliveryOrder, orderItems } = draft.entities;

  switch (action.type) {
    case types.NORMALIZE_DELIVERY_ORDERS: {
      return normalize(action.payload, schema.deliveryOrder);
    }
    case types.CHANGE_DELIVERY_ORDER_STATUS: {
      const { status } = action.payload;
      deliveryOrder[draft.result].status = status;
      return;
    }
    case types.CHANGE_DELIVERY_FOOD_DELETED: {
      const { orderDetailId, deleted } = action.payload;
      orderItems[orderDetailId].deleted = deleted;
      return;
    }
    case types.HIDE_DELIVERY_ORDER: {
      draft.result = null;
    }
  }
};

export default produce(deliveryOrdersReducer, initialState);
