import * as React from "react";

export default function LandingContent() {
  return (
    <>
      <div className="mockups">
        <img
          src="/media/images/Apple-iPhone-11-Pro-Max-Screenshot-0.png"
          alt="Ordering made easy: part 1"
          width={1242}
          height={2688}
        />
        <img
          src="/media/images/Apple-iPhone-11-Pro-Max-Screenshot-1.png"
          alt="Ordering made easy: part 2"
          width={1242}
          height={2688}
        />
        <img
          src="/media/images/Apple-iPhone-11-Pro-Max-Screenshot-2.png"
          alt="WENU DRIVE - pay drive and pick up"
          width={1242}
          height={2688}
        />
        <img
          src="/media/images/Apple-iPhone-11-Pro-Max-Screenshot-3.png"
          alt="Wenu digital menu"
          width={1242}
          height={2688}
        />
        <img
          src="/media/images/Apple-iPhone-11-Pro-Max-Screenshot-4.png"
          alt="Recurring card payments"
          width={1242}
          height={2688}
        />
        <img
          src="/media/images/Apple-iPhone-11-Pro-Max-Screenshot-6.png"
          alt="WENU delivery"
          width={1242}
          height={2688}
        />
      </div>
      {/* </div> */}
      <footer>
        <div className="container">
          <div className="stores">
            <a
              href="https://itunes.apple.com/us/app/wenu/id1291698669?mt=8"
              className="store store--apple"
              target="_blank"
              rel="noopener noreferrer"
              title="To the App Store"
            >
              <img
                src="/media/images/app-store.png"
                alt="Available on the App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=io.wenu"
              className="store store--google"
              target="_blank"
              rel="noopener noreferrer"
              title="To Google Play Store"
            >
              <img
                src="/media/images/google-play.png"
                alt="Android App on Google Play"
              />
            </a>
          </div>

          <div>
            <div className="contact-info">
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z" />
                </svg>
              </span>
              <a href="https://www.facebook.com/wenumenu">wenu on facebook</a>
            </div>

            <div className="contact-info">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0V0z" />
                  <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z" />
                </svg>
              </span>
              <a href="mailto:contact@wenu.io">contact@wenu.io</a>
            </div>
            <div className="contact-info">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0V0z" />
                  <path d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z" />
                </svg>
              </span>
              <div>
                <a href="tel:+995599222403">+995 599 222 403</a>
                <a href="tel:+995595506677">+995 595 50 66 77</a>
              </div>
            </div>
          </div>

          <div>
            <div>wenu &copy; 2021</div>
            <br />
            <a href="/privacy">terms & conditions</a>
          </div>
        </div>
      </footer>
    </>
  );
}
