import { combineReducers } from "redux";

import userReducer from "./userReducer";
// import { categoryReducer } from "./categoryReducer";
import foodReducer from "./foodReducer";
import tableReducer from "./tableReducer";
import setupReducer from "./setupReducer";
// import { orderReducer } from "./orderReducer";
import activeReservationReducer from "./activeReservationReducer";
import outdatedReservationReducer from "./outdatedReservationReducer";
import callReducer from "./callReducer";
import tableOrdersReducer from "./tableOrdersReducer";
import driveThruOrdersReducer from "./driveThruOrdersReducer";
import activeTablesReducer from "./activeTablesReducer";
import driveThruReducer from "./driveThruReducer";
import driverLocationReducer from "./driverLocationReducer";
import deliveryReducer from "./deliveryReducer";
import deliveryOrdersReducer from "./deliveryOrdersReducer";
import unseenReducer from "./unseenReducer";
import pendingCounterReducer from "./pendingCounterReducer";
import reportsReducer from "./reportsReducer";
import registrationReducer from "./registrationReducer";

export default combineReducers({
  userReducer,
  // categoryReducer,
  foodReducer,
  tableReducer,
  setupReducer,
  // orderReducer,
  activeReservationReducer,
  outdatedReservationReducer,
  callReducer,
  tableOrdersReducer,
  driveThruOrdersReducer,
  activeTablesReducer,
  driveThruReducer,
  driverLocationReducer,
  deliveryReducer,
  deliveryOrdersReducer,
  unseenReducer,
  pendingCounterReducer,
  reportsReducer,
  registrationReducer,
});
