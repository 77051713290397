export const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

export function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const {
            coords: { latitude: lat, longitude: lng },
          } = position;
          resolve({ lat, lng });
        },
        () => {
          reject(new Error("Something went wrong"));
        }
      );
    } else {
      reject(new Error("Geolocation not supported"));
    }
  });
}
