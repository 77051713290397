class MovingTitle {
  constructor(writeText, repeatTime, visibleLetters) {
    this.currId = 0;
    this.writeText = writeText;
    this.visibleLetters = visibleLetters;
    this.repeatTime = repeatTime;

    this.timer = null; // interval id
  }

  updateTitle = () => {
    this.currId += 1;
    if (this.currId > this.writeText.length - 1) {
      this.currId = 0;
    }

    const startId = this.currId;
    let endId = startId + this.visibleLetters;

    let finalText;
    if (endId < this.writeText.length - 1) {
      finalText = this.writeText.substring(startId, endId);
    } else {
      const cappedEndId = this.writeText.length;
      endId -= cappedEndId;

      finalText =
        this.writeText.substring(startId, cappedEndId) +
        this.writeText.substring(0, endId);
    }
    document.title = finalText;
  };

  init = () => {
    this.timer = setInterval(this.updateTitle, this.repeatTime);
  };

  finish = () => {
    clearInterval(this.timer);
    document.title = "WENU";
  };
}

export default MovingTitle;
