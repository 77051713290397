import * as types from "../types";

const initialState = {
  disabled: false,
  timezones: null,
  currencies: null,
};

export default function registrationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_DISABLED:
      return { ...state, disabled: true };
    case types.REGISTRATION_GET:
      return {
        ...state,
        timezones: action.payload.timezones,
        currencies: action.payload.currencies,
      };
    default:
      return state;
  }
}
