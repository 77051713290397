// @ts-check

import * as storage from "../providers/storage";
import keywords from "./keywords";

/** @type {Language[]} */
export const KEYS = ["en", "ka", "ru"];
/** @type {Language} */
const DEFAULT_LANGUAGE = "en"; // default, used if language not found in keys

/** @type {Language} */
let language = DEFAULT_LANGUAGE;

const langs = {
  /** @param {Language} [lng] */
  setLocale: (lng) => {
    let _lng = lng;
    if (!lng) {
      _lng = storage.get("lang");
      if (lng === null) {
        _lng = /** @type {Language} */ (navigator.language.slice(0, 2));
      }
    }
    if (KEYS.includes(_lng)) language = _lng;
    storage.set("lang", language);
    return language;
  },

  getSelected: () => {
    /** @type {Language} */
    const _lng = storage.get("lang");
    if (_lng === null) return language;
    return _lng;
  },

  toTitleCase: (str) =>
    str.charAt(0).toUpperCase() + str.substr(1).toLowerCase(),

  toTitleCaseAll: (str) =>
    str.replace(
      /\w\S*/g,
      (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    ),

  /**
   * @param {keyof keywords["en"]} keyword
   * @param {"en" | "ka" | "ru"} [lang] if not included, current state is used
   * */
  translate: (keyword, lang) => {
    const currentLang = lang || language;
    if (keywords[currentLang]) {
      let str;
      if (keywords[currentLang][keyword]) {
        str = keywords[currentLang][keyword];
      } else if (keywords[DEFAULT_LANGUAGE][keyword]) {
        str = keywords[DEFAULT_LANGUAGE][keyword];
      } else {
        str = keyword;
      }

      return str;
    }
    return keyword;
  },
};

export default langs;

/** @typedef {"en"|"ka"|"ru"} Language */
