import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import toast from "./middleware/toast";
import rootReducer from "./reducers/rootReducer";
// import { createLogger } from "redux-logger";

// const logger = createLogger({
// 	collapsed: true,
// });

// Create store:
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	rootReducer,
	compose(composeEnhancers(applyMiddleware(thunk, toast)))
);

// store.subscribe(() => console.log("%cstore", "color: red", store.getState()));

export default store;
