import { toast } from "react-toastify";
import langs from "../../utills/langs";

const toastMiddleware = () => (next) => (action) => {
  if (action.payload && action.payload.errMessage) {
    toast.error(langs.translate(action.payload.errMessage), {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
  if (action.payload && action.payload.task) {
    toast.success(langs.translate(action.payload.task), {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
  return next(action);
};

export default toastMiddleware;
