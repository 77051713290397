import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useToken from "../hooks/useToken";

function RequireLogOut({ children }) {
  const token = useToken();
  const location = useLocation();

  if (token) {
    return <Navigate to={location.state?.from || "/orders"} replace />;
  }

  return children;
}

export default RequireLogOut;
