/* eslint-disable no-new */
/*

  To whoever it may concern: 

  Some socket events like `UPDATE_RESERVATION`, 
  have callback data, which could be used to
  update state in a modular way. Despite this,  
  we often call fetch requests on socket events 
  to download a whole list of data when one single thing updates.

  This was done to save development time, and can be
  optimized later on.

  To see which events have payloads, consult the wenu2-backend wiki,
  or console log `...args`.
  
  */
import { matchPath } from "react-router-dom";
import store from "../store";
import * as types from "../store/types";
import { getActiveTables } from "../store/actions/activeTableActions";
import {
  getDriveThruOrders,
  driveThruOrdersGet,
} from "../store/actions/driveThruActions";
import {
  deliveryOrdersGet,
  getDeliveryOrders,
} from "../store/actions/deliveryActions";
import { login } from "../store/actions/userActions";
import { setupGet } from "../store/actions/setupActions";
import { callByIdGet } from "../store/actions/callActions";
import { setDriverLocation } from "../store/actions/driverLoactionActions";
import * as storage from "../providers/storage";
// import MovingTitle from "../utills/MovingTitle";
import { callAudio, callDriveThruAudio, callAudioLoop } from "./audio";
import {
  activeReservationsGet,
  outdatedReservationsGet,
  acceptReservationsInStore,
  declineReservationsInStore,
  cancelReservationsInStore,
} from "../store/actions/reservationActions";
import {
  // addUnseenOrder,
  addUnseenCalls,
  // addUnseenBills,
  addUnseenReservation,
} from "../store/actions/unseenActions";
import {
  getPendingReservations,
  getPendingOrders,
  getPendingBills,
} from "../store/actions/pendingCounterActions";
import { tableOrdersGet } from "../store/actions/tableOrderActions";
import notifyMe from "./notifications";

const refetchData = (_socket) => {
  if (_socket.connected) {
    const state = store.getState();
    if (state.userReducer.user && state.userReducer.user.id) {
      // EMIT AUTH
      _socket.emit("RESTAURANT_AUTH", state.userReducer.user.id);
      // _socket.emit("USER_AUTH", 1414);
    }

    store.dispatch(getActiveTables());
    store.dispatch(getPendingOrders());
    store.dispatch(getDriveThruOrders());
    store.dispatch(getDeliveryOrders());

    // get zone and table # from url
    if (/orders\/\d+\/\d+/g.test(window.location.href)) {
      const urlArr = window.location.href.split("/");
      const zone = urlArr.slice(-2, -1)[0];
      const table = urlArr.slice(-1)[0].split("?")[0];
      store.dispatch(tableOrdersGet(zone, table));
    }
  }
};

export default function registerSocketListeners(socket) {
  socket.on("connect", () => {
    setTimeout(() => {
      console.log("connect_", socket.connected);
      refetchData(socket);
    }, 1000);
  });

  socket.on("reconnect", () => {
    setTimeout(() => {
      refetchData(socket);
    }, 1000);
  });

  socket.on("reconnect_attempt", () => {
    setTimeout(() => {}, 1000);
  });

  socket.on("reconnecting", () => {
    // console.log("reconnecting", socket.connected);
    setTimeout(() => refetchData(socket), 1000);
  });

  socket.on("RESTART_CLIENT", (delay) => {
    setTimeout(() => window.location.reload(), delay);
  });

  socket.on("STORE_ORDER", ({ type, order }) => {
    callAudio.play();

   

    switch (type) {
      case "delivery": {
        store.dispatch({ type: types.ADD_DELIVERY_ORDER, payload: order });
        notifyMe('RECEIVED DELIVERY ORDER');
        break;
      }
      case "drive-thru": {
        store.dispatch({ type: types.ADD_DRIVE_THRU_ORDER, payload: order });
        notifyMe('RECEIVED DRIVE-THRU ORDER')
        const match = matchPath(
          "orders/drive-thru/:deviceId/*",
          window.location.pathname
        );

        if (match && +match.params.deviceId === +order.deviceId) {
          store.dispatch(driveThruOrdersGet(order.deviceId));
        }

        break;
      }
      case "table": {
        store.dispatch({ type: types.ADD_ACTIVE_TABLE, payload: order });
        notifyMe('RECEIVED TABLE ORDER')
        // Check if current screen is edit order (second screen) and deviceIds match
        const match = matchPath(
          "/orders/table/:zoneNum/:tableNum",
          window.location.pathname
        );

        if (match && +match.params.tableNum === +order.tableNum) {
          store.dispatch(tableOrdersGet(order.zoneNum, order.tableNum));
        }
      }
    }

    store.dispatch(getPendingOrders());
  });

  socket.on("UPDATE_ORDER", (args) => {
    console.log("args", args);
    callAudio.play();
    store.dispatch(getActiveTables());
    store.dispatch(getPendingOrders());
    store.dispatch(getPendingBills());

    // store.dispatch(addUnseenOrder(orderId));
    // store.dispatch(tableOrdersGet(zoneNum, tableNum));
    /*
    
    uncommented following updaters because
    they were interfiring with state updates on a current device.

    arg.deviceId is undefined
    and driveThruOrdersGet accepts plateNum not deviceId ¯\_(ツ)_/¯

    (We really need to think of a better way of updating state with sockets)
    
    
    */
    // store.dispatch(driveThruOrdersGet(deviceId));
    // store.dispatch(deliveryOrdersGet(deviceId));
  });

  socket.on("UPDATE_ORDER_STATUS", ({ orderId, status, type }) => {
    switch (type) {
      case "delivery": {
        const deliveryReducer = store.getState().deliveryOrdersReducer;
        const { result } = deliveryReducer;
        if (+result === +orderId) {
          store.dispatch({
            type: "CHANGE_DELIVERY_ORDER_STATUS",
            payload: { status },
          });
        }
        break;
      }
      case "drive-thru": {
        const driveThruReducer = store.getState().driveThruOrdersReducer;
        const { result } = driveThruReducer;

        const match = result.find((i) => +i === +orderId);

        if (match) {
          store.dispatch({
            type: "CHANGE_DRIVE_THRU_ORDER_STATUS",
            payload: { orderId, status },
          });
        }
        break;
      }
      case "table": {
        const tableReducer = store.getState().tableOrdersReducer;
        const { result } = tableReducer;

        const match = result.find((i) => +i === +orderId);

        if (match) {
          store.dispatch({
            type: "CHANGE_ORDER_STATUS",
            payload: { orderId, status },
          });
        }
        break;
      }
    }
  });

  socket.on(
    "UPDATE_GROUP_STATUS",
    ({ groupStatus, type, deviceId, tableNum }) => {
      switch (type) {
        case "delivery": {
          const { deliveryReducer } = store.getState();
          // This might be not necessary
          const orderExists = deliveryReducer.find(
            (o) => +o.deviceId === +deviceId
          );

          if (orderExists) {
            store.dispatch({
              type: "CHANGE_DELIVERY_STATUS",
              payload: { status: groupStatus, deviceId },
            });
          }

          break;
        }
        case "drive-thru": {
          const { driveThruReducer } = store.getState();
          // This might be not necessary
          const orderExists = driveThruReducer.find(
            (o) => +o.deviceId === +deviceId
          );

          if (orderExists) {
            store.dispatch({
              type: "CHANGE_DRIVE_THRU_STATUS",
              payload: { status: groupStatus, deviceId },
            });
          }

          break;
        }
        case "table": {
          const { activeTablesReducer } = store.getState();
          // This might be not necessary
          const orderExists = activeTablesReducer.find(
            (o) => +o.tableNum === +tableNum
          );

          if (orderExists) {
            store.dispatch({
              type: "CHANGE_TABLE_STATUS",
              payload: { status: groupStatus, tableNum },
            });
          }

          break;
        }
      }
    }
  );

  socket.on("HIDE_ORDER", ({ orderId, tableNum, zoneNum, deviceId }) => {
    console.log("HIDE_ORDER", orderId, tableNum, zoneNum);
    callAudio.play();
    store.dispatch(getActiveTables());
    // store.dispatch(addUnseenOrder(orderId));
    store.dispatch(getPendingOrders());

    store.dispatch(tableOrdersGet(zoneNum, tableNum));
    store.dispatch(driveThruOrdersGet(deviceId));
    store.dispatch(deliveryOrdersGet(deviceId));
  });

  socket.on("RESET_TABLE", ({ tableNum, zoneNum }) => {
    console.log("RESET_TABLE", tableNum, zoneNum);
    callAudio.play();
    store.dispatch(getActiveTables());
    store.dispatch(tableOrdersGet(zoneNum, tableNum));
    store.dispatch(getPendingOrders());

    store.dispatch(getPendingBills());
  });

  socket.on("RESET_DELIVERY_ORDER", () => {
    callAudio.play();
    store.dispatch(getDeliveryOrders());
    store.dispatch(getPendingOrders());
  });

  socket.on("NEW_CALL", (callId) => {
    callAudio.play();
    console.log("NEW_CALL", callId);
    store.dispatch(callByIdGet(callId));
    store.dispatch(addUnseenCalls());
    store.dispatch(getPendingOrders());
  });

  socket.on("STORE_RESERVATION", (reservationId) => {
    callAudio.play();
    store.dispatch(activeReservationsGet());
    store.dispatch(outdatedReservationsGet(0));
    store.dispatch(addUnseenReservation(reservationId));
    store.dispatch(getPendingReservations());
  });

  socket.on("ACCEPT_RESERVATIONS", (reservationIds) => {
    store.dispatch(acceptReservationsInStore(reservationIds));
    store.dispatch(getPendingReservations());
  });

  socket.on("DECLINE_RESERVATIONS", (reservationIds) => {
    store.dispatch(declineReservationsInStore(reservationIds));
    store.dispatch(getPendingReservations());
  });

  socket.on("CANCEL_RESERVATION", (reservationId) => {
    callAudio.play();
    store.dispatch(addUnseenReservation(+reservationId));
    store.dispatch(cancelReservationsInStore([reservationId]));
    store.dispatch(getPendingReservations());
  });

  socket.on("NEW_BILL", (order) => {
    console.log("NEW_BILL", order);
    callAudio.play();
    store.dispatch(getActiveTables());
    store.dispatch(getPendingBills());
    store.dispatch(tableOrdersGet(order.zoneNum, order.tableNum));
    // store.dispatch(getPendingOrders());
  });

  socket.on("UPDATE_RESERVATION", () => {
    // const token = storage.get("token");
    // store.dispatch(login(token));
    store.dispatch(setupGet());
  });

  socket.on("DRIVE_THRU_RESET", () => {
    callAudio.play();
    // callDriveThruAudio(true);
    callAudioLoop("drive", true);

    store.dispatch(getDriveThruOrders());
    store.dispatch(getPendingOrders());
  });

  socket.on("UPDATE_ORDER_PLATE_NUM", () => {
    console.log("UPDATE_PLATE_NUMBER");
    callAudio.play();
    store.dispatch(getDriveThruOrders());
  });

  socket.on("driver-location-status", async (data) => {
    console.log("DATA::", data);
    if (data.duration.text === "1 min") {
      callAudioLoop("drive");
      // console.log("SOCKET_DATA:: ", data);
      // const { driverLocationReducer } = store.getState();
      // if (Object.keys(driverLocationReducer).includes(data.deviceId)) {
      //   if (driverLocationReducer[data.deviceId].duration.text !== "1 min") {
      //     // uncomment when build

      //     // callDriveThruAudio();
      //     callAudioLoop("drive");
      //   }
      // }
    }
    store.dispatch(setDriverLocation(data));
  });

  if (!socket.connected) {
    socket.open();
  }
}
