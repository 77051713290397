import * as types from "../types";
import { callDriveThruAudio, callAudioLoop } from "../../utills/audio";

// import { toast } from "react-toastify";
// import langs from "../../utills/langs";

export default function driveThruReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_DRIVE_THRU_ORDERS:
      return payload;
    case types.ADD_DRIVE_THRU_ORDER: {
      // Check if order already exists
      const devicesIds = state.map((o) => o.deviceId);
      const orderExists = devicesIds.includes(payload.deviceId);
      let updatedState = [];
      if (orderExists) {
        // Update only order status if exists
        updatedState = state.map((o) => {
          const order = o;
          if (+order.deviceId === +payload.deviceId) {
            order.status = "PENDING";
          }
          return order;
        });
      } else {
        // Add new order
        updatedState = [...state, payload];
      }

      const sortedStaet = updatedState.sort((o) =>
        o.status === "PENDING" ? -1 : 1
      );
      return sortedStaet;
    }
    case types.RESET_DRIVE_THRU: {
      // return state.filter((order) => order.deviceId !== +payload.deviceId);
      const filteredState = state.filter(
        (order) => order.deviceId !== +payload.deviceId
      );

      const isDriverClose = filteredState.some(
        (o) => o.driveThruTime === "1 min"
      );
      if (!isDriverClose) {
        console.log("closeorder");
        // callDriveThruAudio(true);
        callAudioLoop('drive', true)
        
       
      }

      return filteredState;
    }
    case "CHANGE_DRIVE_THRU_STATUS": {
      const { status, deviceId } = payload;
      const updatedState = state.map((order) => {
        const _order = order;
        if (+_order.deviceId === +deviceId) {
          _order.status = status;
        }
        return _order;
      });

      const sortedStaet = updatedState.sort((o) =>
        o.status === "PENDING" ? -1 : 1
      );
      return sortedStaet;
    }
    default:
      return state;
  }
}
