import { useSelector } from "react-redux";
import useStorageDefault from "./useStorageDefault";

export default function useIsAdmin() {
  const isAdmin = useStorageDefault(
    "isAdmin",
    useSelector((state) => state.userReducer?.user?.restaurantUser?.isAdmin)
  );

  return !!isAdmin;
}
