// @ts-check

import React from "react";

/**
 * changes array if and only if its items change (reorder, referanceChange, size change)
 * useful when you want to pass array as a useReducer dependancy
 *
 * @param {any[]} array
 */
export default function useArrayMemo(array) {
  const persistantArray = React.useRef([]);

  const sameLength = array.length === persistantArray.current.length;

  const deepEquals =
    sameLength &&
    array.reduce((a, b, i) => a && persistantArray.current[i] === b, true);

  if (!deepEquals) {
    persistantArray.current = array;
  }

  return persistantArray.current;
}
