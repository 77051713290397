import React from "react";
import { useMatch } from "react-router-dom";
import LandingContent from "./landingLayout/LandingContent";
import LandingHeader from "./landingLayout/LandingHeader";
import "./LandingLayout.css";
import RequireLogOut from "../components/RequireLogOut";

function LandingLayout() {
  const match = useMatch("/");

  return (
    <RequireLogOut>
      <div className="main">
        <LandingHeader />
        {/* render rest of the page only if it's index page */}
        {match && <LandingContent />}
      </div>
    </RequireLogOut>
  );
}

export default LandingLayout;
