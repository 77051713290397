import { schema } from 'normalizr';

export const orderItem = new schema.Entity(
  'orderItems',
  {},
  { idAttribute: 'orderDetailId' }
);

export const driveThruOrder = new schema.Entity('driveThruOrders', {
  orderItems: [orderItem],
});
