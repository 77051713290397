import * as React from "react";
import { Outlet } from "react-router-dom";
import RequireAdmin from "../components/RequireAdmin";

export default function AdminLayout() {
  return (
    <RequireAdmin>
      <Outlet />
    </RequireAdmin>
  );
}
