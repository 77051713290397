import * as React from "react";
import { useSelector } from "react-redux";
import { callAudioLoop } from "../utills/audio";

export default function useAudioLoop() {
  const pending = useSelector((state) => state.pendingCounterReducer.orders);

  React.useEffect(() => {
    console.log("pending", pending);
    if (+pending > 0) {
      // callPendingOrderAudio()
      callAudioLoop("pending");
    } else {
      console.log("is not pending");
      // callPendingOrderAudio(true)
      callAudioLoop("pending", true);
    }
  }, [pending]);
}
