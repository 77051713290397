import React from "react";
import "./OnlineSnackbar.scss";

export default function OnlineSnackbar() {
	return (
		<div className="online-snackbar">
			You are currently offline. Some functions might not work.
		</div>
	);
}
