import * as types from "../types";
// import { toast } from "react-toastify";
// import langs from "../../utills/langs";

const initialState = {
  disabled: false,
  calls: [],
};

export default function callReducer(state = initialState, action = {}) {
  // if (action.payload && action.payload.task && action.type) {
  //   if (action.type.startsWith("CALL")) {
  //     toast.success(langs.translate(action.payload.task), {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     });
  //   }
  // }
  // if (
  //   action.payload &&
  //   action.type &&
  //   action.payload.error &&
  //   action.payload.errMessage
  // ) {
  //   if (action.type.startsWith("CALL")) {
  //     toast.error(langs.translate(action.payload.errMessage), {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     });
  //   }
  // }

  switch (action.type) {
    case types.CALL_BY_ID_GET:
      return { ...state, calls: state.calls.concat(action.payload) };
    case types.TERMINATE_CALL:
      return {
        ...state,
        calls: state.calls.filter((c) => c.deviceId !== action.payload),
      };
    case types.CALLS_GET:
      return { ...state, calls: action.payload };
    default:
      return state;
  }
}
