import { toast } from "react-toastify";
import axios from "../../providers/axios";
import * as storage from "../../providers/storage";
import socket from "../../utills/socket";
import * as types from "../types";

export function logout() {
  return (dispatch, getState) => {
    /*
      TODO: wipe out entire redux state;
      call storage.clear only on user data
    */
    const { userReducer: state } = getState();
    storage.clear();
    axios.setHeader("token", "");
    if (state.user) {
      socket.emit("RESTAURANT_DEAUTH", state.user.id);
    }
    dispatch({ type: types.USER_LOGOUT });
  };
}

/**
 * tries to authenticate user with username & password and other optional fields
 *
 * @typedef LoginResponse
 * @property {boolean} error
 * @property {string} [token]
 * @property {string} [errorMessage]
 * @property {boolean} [masterPass]
 * @property {import("../../utills/types").RestaurantSetup} data
 *
 * @param {object} payload
 * @param {string} [payload.username]
 * @param {string} [payload.password]
 * @param {string} [payload.recaptchaResponse]
 * @param {boolean} [payload.ignoreRecaptcha] // FIXME: prob security risk
 *
 */
export function login(payload) {
  return (dispatch) => {
    // wipe out data when trying to log in
    dispatch(logout());

    return axios.post("/api/login", payload).then((response) => {
      const { data } = /** @type {{data: LoginResponse}} */ (response);
      if (data.error) {
        // probably a validation error. Show feedback from the catch block
        throw new Error(data);
      }

      // if tfa is not activated, commit final token & user data to the store
      // if (!data.data.restaurantUser.tfa_verified) {

      // persistLoginResponse(data);
      storage.set("isAdmin", !!data.data.restaurantUser.isAdmin);
      storage.set("token", data.token);

      if (data.data.restaurantUser.isWenuAdmin) {
        storage.set("wenuAdminToken", data.token);
      } else {
        storage.set("wenuAdminToken", "");
      }

      socket.emit("RESTAURANT_AUTH", data.data.id);
      axios.setHeader("token", data.token);

      dispatch({
        type: types.USER_LOGIN,
        payload: data,
      });
      // }

      // return usefull data for ui/validation
      // return {
      //   token: data.token,
      //   isMasterPass: data.masterPass,
      //   isTfaActivated: !!data.data.restaurantUser.tfa_verified,
      // };
    });
  };
}

/**
 * validates tfa token. if valid, replaces the token and logs in the user.
 * if not, throws error
 *
 * @param {object} options
 * @param {string} options.token - temporary token from login attempt
 * @param {string} options.tfaToken - tfa validation code
 * @param {boolean} options.masterPass - is authenticating with master pass
 * @returns
 */
export function validateTfa({ token, tfaToken, masterPass }) {
  return (dispatch) => {
    axios.setHeader("token", token);
    return axios
      .post("/api/2fa/validate", { token: tfaToken, masterPass })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          // probably incorrect pin. catch from validateTfa promise
          throw new Error(data);
        }

        // persistLoginResponse(data);
        storage.set("isAdmin", !!data.data.restaurantUser.isAdmin);
        storage.set("token", data.token);

        axios.setHeader("token", data.token);

        // resend the authorizatoin event
        socket.emit("RESTAURANT_AUTH", data.data.id);

        dispatch({
          type: types.USER_LOGIN,
          payload: response.data,
        });
      });
  };
}

/**
 * logs in user if token is valid. if not, wipes out user data
 * @param {string} token
 */
export function validateToken(token) {
  return (dispatch) => {
    axios.setHeader("token", token);

    return axios
      .post("/api/login")
      .then((response) => {
        const { data } = /** @type {{data: LoginResponse}} */ (response);
        if (data.error) {
          // delete data. only one try for the token to be right
          dispatch(logout());
          throw new Error(data.error);
        }
        // persist data because isAdmin might have changed
        // persistLoginResponse(data);
        storage.set("isAdmin", !!data.data.restaurantUser.isAdmin);
        storage.set("token", data.token);
        // resend the authorizatoin event
        socket.emit("RESTAURANT_AUTH", data.data.id);

        dispatch({
          type: types.USER_LOGIN,
          payload: response.data,
        });
      })
      .catch((err) => {
        if (!err.status) {
          // network error
          toast.error("Possible connection problem. Please reload", {
            position: "top-center",
            autoClose: false,
          });
        }
        throw err;
      });
  };
}

export function changeLang(lang) {
  return {
    type: types.CHANGE_LANG,
    payload: { lang },
  };
}

export function languagesGet() {
  return (dispatch) =>
    axios.get("/api/language/languagesGet").then((response) => {
      const { data } = response;
      dispatch({ type: types.LANGUAGES_GET, payload: data });
    });
}

export function languageAdd(payload) {
  return (dispatch) =>
    axios.post("/api/language/languageAdd", payload).then((response) => {
      const { data } = response;
      dispatch({ type: types.LANGUAGE_ADD, payload: data });
    });
}

export function languageRemove(payload) {
  return (dispatch) =>
    axios.post("/api/language/languageRemove", payload).then((response) => {
      const { data } = response;
      dispatch({ type: types.LANGUAGE_REMOVE, payload: data });
    });
}

export function languageMakePrimary(payload) {
  return (dispatch) =>
    axios
      .post("/api/language/languageMakePrimary", payload)
      .then((response) => {
        const { data } = response;
        dispatch({ type: types.LANGUAGE_MAKE_PRIMARY, payload: data });
      });
}

export function languagePublish(payload) {
  return (dispatch) =>
    axios.post("/api/language/languagePublish", payload).then((response) => {
      const { data } = response;
      dispatch({ type: types.LANGUAGE_PUBLISH, payload: data });
    });
}
