import * as types from "../types";
import axios from "../../providers/axios";
import * as storage from "../../providers/storage";

// eslint-disable-next-line import/prefer-default-export
export const getActiveTables =
  (zoneNum = null) =>
  (dispatch) => {
    const token = storage.get("token");
    if (token) {
      axios.setHeader("token", token);
      const url = zoneNum ? `/api/orders/${zoneNum}` : "/api/orders";

      axios
        .get(url)
        .then((response) => {
          const { data } = response;
          dispatch({ type: types.SET_ACTIVE_TABLES, payload: data });
        })
        .catch((err) => {
          console.log("activeTableActions::getActiveTables", err);
          return Promise.reject(err);
        });
    }
  };
