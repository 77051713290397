import * as React from "react";
import { Outlet } from "react-router-dom";
import RequireToken from "../components/RequireToken";
import MainMenu from "../components/MainMenu";

function AppLayout() {
  return (
    <RequireToken>
      <MainMenu />
      <Outlet />
    </RequireToken>
  );
}

export default AppLayout;
