// @ts-check
import * as React from "react";

/** function wrapper that makes function run only once (lodash _.once) */
function once(fn, context) {
  let result;
  let fnRef = fn;
  return (...args) => {
    if (fnRef) {
      result = fn.apply(context || this, args);
      fnRef = null;
      return result;
    }
    return undefined;
  };
}

/**
 * @param {(...args: any[]) => any} fn
 * @param {object} [context]
 */
export default function useOnce(fn, context) {
  return React.useMemo(() => once(fn, context), [fn, context]);
}
