const notifyMe = (msg) => {
  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("Browser does not support notifications api!");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    var notification = new Notification(msg);
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        var notification = new Notification(msg);
      }
    });
  }
};

export default notifyMe;
