/*




WARNING: unchecked code ahead

Registration was temporaryly taken down when we refactored authentication and
updated to react route v6. 

Thoroughly check the code before adding registration back






*/

// @ts-check
import React, { useCallback, useState } from "react";
import { Icon } from "react-icons-kit";
import { arrows_slim_left as LeftArrow } from "react-icons-kit/linea/arrows_slim_left";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import isEmail from "validator/es/lib/isEmail";
import useValidation from "../hooks/useValidation";
import axios from "../providers/axios";
import * as userActions from "../store/actions/userActions";
import TextField from "../components/TextField";
import "./LandingRegistration.scss";

const required = (field) => (field.length > 0 ? "" : "Required");
const min6 = (field) =>
  field.length >= 6 ? "" : "Please use at least 6 characters";
const min2 = (field) =>
  field.length >= 2 ? "" : "Please use at least 2 characters";
const validEmail = (field) =>
  isEmail(field) ? "" : "Please use a valid Email";
const startsWithPlus = (field) =>
  field[0] === "+" ? "" : 'Start with "+" sign and the country code';

function LandingRegistration({ login }) {
  const [key, setKey] = useState(0);

  const [serverError, setServerError] = useState({ email: "", phone: "" });

  const [restaurant, setRestaurant, restaurantError] = useValidation("", [
    required,
    min2,
  ]);
  const [email, setEmail, emailError] = useValidation("", [
    required,
    validEmail,
  ]);
  const [phone, setPhone, phoneError] = useValidation("", [
    required,
    startsWithPlus,
  ]);

  const handleFirstPage = () => {
    const _restaurantError = setRestaurant(restaurant, false);
    const _emailError = setEmail(email, false);
    const _phoneError = setPhone(phone, false);
    if (!_restaurantError && !_emailError && !_phoneError) {
      setKey(1);
    }
  };

  const [username, setUsername, usernameError] = useValidation("", [
    required,
    min6,
  ]);
  const [password, setPassword, passwordError] = useValidation("", [
    required,
    min6,
  ]);
  const samePassword = useCallback(
    (field) =>
      field === password && field.length !== 0 ? "" : "Passwords do not match",
    [password]
  );
  const [confirmation, setConfirmation, confirmationError] = useValidation("", [
    required,
    samePassword,
  ]);

  const handleSecondScreen = (e) => {
    e.preventDefault();
    const _usernameError = setUsername(username, false);
    const _passwordError = setPassword(password, false);
    const _confirmationError = setConfirmation(confirmation, false);
    if (!_usernameError && !_passwordError && !_confirmationError) {
      axios
        .post("/api/restaurant/register", {
          restaurantName: restaurant,
          username,
          email,
          mobile: phone,
          password,
          repeat: confirmation,
        })
        .then(({ data }) => {
          console.log(data);
          if (data.success) setKey(2);
        })
        .catch((err) => {
          setKey(0);
          setUsername("", false);
          setPassword("", false);
          setConfirmation("", false);
          setRestaurant("", false);
          console.log(err.response);
          if (err.response.status === 500) {
            switch (err.response.data.error.type) {
              case "RESTAURANT_WITH_EMAIL_EXISTS":
                setServerError((state) => ({
                  ...state,
                  email: "Restaurant with this email already exists",
                }));
                break;
              case "RESTAURANT_WITH_MOBILE_EXISTS":
                setServerError((state) => ({
                  ...state,
                  phone: "Restaurant with this mobile number already exists",
                }));
                break;
              default:
                break;
            }
          }
          toast.error("We weren't able to Sign you up with these credentials", {
            position: toast.POSITION.BOTTOM_RIGHT,
            // @ts-ignore - there's no duration option, but can't bother to check if this actually does something
            duration: 3000,
          });
        });
    }
  };

  const [smsCode, setSmsCode] = useState("");

  const validatePin = () => {
    axios
      .post("/api/restaurant/validatePin", {
        mobile: phone,
        pin: smsCode,
      })
      .then(({ data }) => {
        if (data.success) {
          login({
            username,
            password,
            ignoreRecaptcha: true,
          });
        }
      })
      .catch(() => {
        // login error will be catched here too
        toast.error("Please check the phone number and try again", {
          position: toast.POSITION.BOTTOM_RIGHT,
          // @ts-ignore - there's no duration option, but can't bother to check if this actually does something
          duration: 3000,
        });
      });
  };

  const goBack = () => setKey(key - 1);

  const forms = (() => {
    if (key === 0) {
      return (
        <div>
          <TextField
            error={restaurantError}
            helperText={restaurantError}
            label="Restaurant name*"
            variant="filled"
            style={{ width: "100%", marginBottom: "20px" }}
            value={restaurant}
            onChange={(e) => setRestaurant(e.target.value)}
          />
          <TextField
            error={emailError}
            helperText={emailError}
            variant="filled"
            label="Email*"
            id="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            style={{ width: "100%", marginBottom: "20px" }}
          />
          <TextField
            error={phoneError || serverError.phone}
            helperText={
              phoneError ||
              serverError.phone ||
              'Start with "+" sign and the country code. Spaces are allowed'
            }
            variant="filled"
            label="Phone number*"
            id="phone"
            type="tel"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            style={{ width: "100%", marginBottom: "20px" }}
          />

          <div className="landing-form-bottom">
            <button
              type="button"
              className="landing-auth-button"
              onClick={handleFirstPage}
            >
              Next
            </button>
            <div className="screen-counter">1/3</div>
          </div>
        </div>
      );
    }
    if (key === 1) {
      return (
        <div>
          <TextField
            error={usernameError}
            helperText={usernameError}
            variant="filled"
            label="Username*"
            id="username"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            style={{ width: "100%", marginBottom: "20px" }}
          />

          <TextField
            error={passwordError}
            helperText={passwordError}
            variant="filled"
            label="Password*"
            id="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            style={{ width: "100%", marginBottom: "20px" }}
          />

          <TextField
            error={confirmationError}
            helperText={confirmationError}
            variant="filled"
            label="Repeat Password"
            id="confirmation"
            type="password"
            onChange={(e) => setConfirmation(e.target.value)}
            value={confirmation}
            style={{ width: "100%", marginBottom: "20px" }}
          />

          <div className="landing-form-bottom">
            <div className="landing-form-bottom-left">
              <button
                type="button"
                className="landing-back-button"
                onClick={goBack}
                aria-label="back"
              >
                <Icon icon={LeftArrow} size={32} />
              </button>
              <button
                type="submit"
                className="landing-auth-button"
                onClick={handleSecondScreen}
              >
                Submit
              </button>
            </div>
            <div className="screen-counter">2/3</div>
          </div>
        </div>
      );
    }
    if (key === 2) {
      <div className="confirm-phone">
        <div>
          <p>
            <strong style={{ fontWeight: "bold" }}>Congrats!</strong> You
            successfully registered your restaurant!
          </p>
          <br />
          <p>
            we&apos;ve sent you a small code at {phone}. Please type the code
            below and enjoy Wenu
          </p>
          <br />
          <br />

          <TextField
            variant="filled"
            label="SMS code"
            id="smsCode"
            onChange={(e) => setSmsCode(e.target.value)}
            value={smsCode}
            type="number"
            style={{ width: "100%", marginBottom: "20px" }}
            helperText={`Enter Code we sent you at ${phone}`}
          />
        </div>
        <div className="landing-form-bottom">
          <div className="landing-form-bottom-left">
            <button
              type="button"
              className="landing-back-button"
              onClick={goBack}
              aria-label="back"
            >
              <Icon icon={LeftArrow} size={32} />
            </button>
            <button
              type="button"
              className="landing-auth-button"
              onClick={validatePin}
            >
              Validate
            </button>
          </div>
          <div className="screen-counter">3/3</div>
        </div>
      </div>;
    }
    return null;
  })();

  return (
    <div className="landing-registration pattern-dots-md">
      <div className="pattern pattern-dots-md" />

      <form>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={key}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
          >
            {forms}
          </CSSTransition>
        </SwitchTransition>
      </form>
    </div>
  );
}

const mapDispatchToProps = {
  login: userActions.login,
};

export default connect(null, mapDispatchToProps)(LandingRegistration);
