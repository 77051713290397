import { useEffect, useRef, useState } from "react";
import useArrayMemo from "./useArrayMemo";

const getError = (field, validators) =>
  validators
    .map((validator) => validator(field)) // return errors
    .find((error) => error !== ""); // return first error

/**
 * @param {any} defaultState
 * @param {((field: any) => string)[]} validators
 */
export default function useValidation(defaultState, validators) {
  const [field, setField] = useState(defaultState);
  const [error, setError] = useState("");
  const [debounce, setDebounce] = useState(true);

  const firstRender = useRef(true);
  const timeout = useRef(null);

  const persistantValidators = useArrayMemo(validators);

  useEffect(() => {
    if (!firstRender.current) {
      let err;
      if (error || !debounce) {
        err = getError(field, persistantValidators);
        setError(err);
      } else {
        timeout.current = setTimeout(() => {
          err = getError(field, persistantValidators);
          setError(err);
        }, 400);
      }
    }

    firstRender.current = false;
    return () => {
      clearTimeout(timeout.current);
    };
  }, [field, debounce, error, persistantValidators]);

  /**
   *
   * @param {string} field
   * @param {boolean} debounce
   */
  const setFieldAndDebounce = (_field, _debounce = true) => {
    setField(_field);
    setDebounce(_debounce);
    return getError(_field, validators);
  };

  return [field, setFieldAndDebounce, error];
}
