import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useToken from "../hooks/useToken";

function RequireToken({ children }) {
  const token = useToken();

  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default RequireToken;
