import * as types from "../types";
import axios from "../../providers/axios";
import * as storage from "../../providers/storage";
// import orders from "./tableOrdersDump";
// import queryString from "query-string";

/**
 * get orders and dispatch NORMALIZE_TABLE_ORDERS action on success
 * @param  {number|string} zoneNum - Zone number; match.params.zoneNum
 * @param  {number|string} tableNum - Table number; match.params.tableNum
 * @param  {string} iso - (ka|en|ru)
 */
export const tableOrdersGet = (zoneNum, tableNum) => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);

    axios
      .get(`/api/orders/${zoneNum}/${tableNum}`)
      .then((response) => {
        const { data } = response;

        dispatch({ type: types.NORMALIZE_TABLE_ORDERS, payload: data });
      })
      .catch((err) => {
        console.log("tableOrderActions::tableOrdersGet", err);
        return Promise.reject(err);
      });
  }
};

/**
 * send post action that adds food to the order and
 * dispatch ADD_FOOD_TO_ORDER action with returned data on success
 * @param {Object} params - data to send to the server
 * @param  {number} params.iso - Curent locale
 * @param  {number} params.orderId
 * @param  {number} params.foodId - unique for the food itself, not unique for orderItem
 * @param  {number} params.quantity
 */
export const addFood =
  ({ iso, orderId, foodId, quantity }) =>
  (dispatch) => {
    const token = storage.get("token");
    if (token) {
      axios
        .post(`/api/orders/${orderId}/addFood`, { foodId, quantity, iso })
        .then((response) => {
          const { data } = response;
          console.log("data", data);
          if (data) {
            dispatch({
              type: types.ADD_FOOD_TO_ORDER,
              payload: {
                orderItem: data,
                orderId,
              },
            });
          } else {
            dispatch({
              type: "TOAST",
              payload: {
                errMessage: data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.log("tableOrderActions::addFood", err);
          return Promise.reject(err);
        });
    }
  };

/**
 * @param {Object} params - data to send to the server
 * @param {number} params.orderId
 */
export const changeStatus =
  ({ orderId }) =>
  () => {
    const token = storage.get("token");
    if (token) {
      axios
        .post(`/api/orders/${orderId}/updateStatus`, { status: "ACCEPTED" })
        .then(() => {
          console.log("Disabled disptach");
          // dispatch({
          //   type: types.CHANGE_ORDER_STATUS,
          //   payload: { orderId, status: "ACCEPTED" },
          // });
        })
        .catch((err) => {
          console.log("tableOrderActions::changeStatus", err);
          return Promise.reject(err);
        });
    }
  };

export const hideOrder = (orderId) => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);
    axios
      .post(`/api/orders/${orderId}/hide`)
      .then(() => {
        dispatch({
          type: types.HIDE_ORDER,
          payload: orderId,
        });
      })
      .catch((err) => {
        console.log("tableOrderActions::hideOrder", err);
        return Promise.reject(err);
      });
  }
};

/**
 * @param {Object} param - data to send to the server
 * @param {number} param.orderDetailId - unique identifier of orderItem
 * @param {boolean} param.deleted - next state
 */
export const changeFoodDeleted =
  ({ orderDetailId, deleted }) =>
  (dispatch) => {
    const token = storage.get("token");
    if (token) {
      axios
        .post(`/api/orderDetails/${orderDetailId}/updateStatus`, {
          status: deleted ? "REJECTED" : "PENDING",
        })
        .then(() => {
          dispatch({
            type: types.CHANGE_FOOD_DELETED,
            payload: { orderDetailId, deleted },
          });
        })
        .catch((err) => {
          console.log("tableOrderActions::changeFoodDeleted", err);
          return Promise.reject(err);
        });
    }
  };

/**
 * send post action to delete all accepted tables and dispatch RESET_TABLE action on success
 * @param {number} zoneNum - Zone number
 * @param {number} tableNum - Table number
 */
export const resetTable = (zoneNum, tableNum) => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios
      .post(`/api/orders/resetTable`, {
        tableNum,
        zoneNum,
      })
      .then(() => {
        dispatch({ type: types.RESET_TABLE });
      })
      .catch((err) => {
        console.log("tableOrderActions::resetTable", err);
        return Promise.reject(err);
      });
  }
};
