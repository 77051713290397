import * as types from "../types";

export default function activeReservationReducer(state = [], action = {}) {
  switch (action.type) {
    case types.ACTIVE_RESERVATIONS_GET:
      return action.payload;

    case types.RESERVATIONS_ACCEPT:
      return state.map((r) => {
        const _r = r;
        if (action.payload.ids.includes(r.id)) {
          _r.status = "ACCEPTED";
        }
        return _r;
      });

    case types.RESERVATIONS_DECLINE:
      return state.map((r) => {
        const _r = r;
        if (action.payload.ids.includes(r.id)) {
          _r.status = "DECLINED";
        }
        return _r;
      });

    case types.RESERVATIONS_CANCEL:
      return state.map((r) => {
        const _r = r;
        if (action.payload.ids.includes(r.id)) {
          _r.status = "CANCELLED";
        }
        return _r;
      });

    case types.ACTIVE_RESERVATIONS_CLEAR:
      return [];

    default:
      return state;
  }
}
