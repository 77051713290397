// @ts-check

import * as React from "react";
import * as storage from "../providers/storage";
import useOnce from "./useOnce";

/**
 * initialy returns value from local storage.
 * when value is updated, returns value evry subsequent time (even if value becomes undefined)
 * @param {string} key
 * @param {any} value
 */
export default function useStorageDefault(key, value) {
  const getValue = React.useCallback(
    (_key, _value) => _value || storage.get(_key),
    []
  );
  /**
   * only returns value for the first time, otherwise returned value is undefined.
   * if initial `value` is defined returns value, otherwise gets value from localstorage with `key`
   */
  const getDefaultValue = useOnce(getValue);

  const item = React.useMemo(
    () => getDefaultValue(key, value) || value,
    [key, value, getDefaultValue]
  );

  return item;
}
