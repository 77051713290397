import * as types from "../types";

import axios from "../../providers/axios";
import * as storage from "../../providers/storage";

export function callByIdGet(callId) {
  return (dispatch, getState) => {
    const token = storage.get("token");
    if (token) {
      const { lang } = getState().userReducer;
      axios.setHeader("token", token);

      axios
        .get(`/api/calls/${callId}/${lang}`)
        .then((response) => {
          const { data } = response;
          dispatch({ type: types.CALL_BY_ID_GET, payload: data });
        })
        .catch((err) => {
          console.log("callActions::callByIdGet", err);
          return Promise.reject(err);
        });
    }
  };
}

export function terminateCall(deviceId) {
  return { type: types.TERMINATE_CALL, payload: deviceId };
}

export function callsGet() {
  return (dispatch, getState) => {
    const token = storage.get("token");
    if (token) {
      const { lang } = getState().userReducer;
      axios.setHeader("token", token);

      axios
        .get(`/api/calls/${lang}`)
        .then((response) => {
          const { data } = response;
          dispatch({
            type: types.CALLS_GET,
            payload: data,
          });
        })
        .catch((err) => {
          console.log("callActions::callsGet", err);
          return Promise.reject(err);
        });
    }
  };
}
