import * as types from "../types";
// import { toast } from "react-toastify";
// import langs from "../../utills/langs";

const initialState = {
  disabled: false,
  foodsGot: [],
  foodsForEditOrder: [],
};

export default function foodReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_DISABLED:
      return { ...state, disabled: true };
    case types.FOOD_UPDATE:
    case types.FOODS_GET:
      return { ...state, ...action.payload, disabled: false };
    case types.FOODS_GET_BY_CATEGORIES:
      return { ...state, ...action.payload, disabled: false };
    case types.FOOD_ADD:
      return { ...state, ...action.payload, disabled: false };
    case types.FOOD_PUBLISH:
      return (() => {
        const filtered = state.foodsGot.filter(
          (el) => el.id !== action.payload.id
        );
        const published = {
          ...state.foodsGot.filter((el) => el.id === action.payload.id)[0],
        };
        published.published = action.payload.published;
        return {
          ...state,
          foodsGot: [...filtered, published],
          disabled: false,
        };
      })(state);
    case types.FOOD_REMOVE:
      return (() => {
        const filtered = state.foodsGot.filter(
          (el) => el.id !== action.payload.id
        );
        return {
          ...state,
          foodsGot: filtered,
          disabled: false,
        };
      })(state);
    // case types.FOOD_IMAGE_REMOVE:
    //   return (() => {
    //     const foodImg = action.payload.error ? state.foodImg : "";
    //     // console.log("state", state);

    //     return { ...state, foodImg, disabled: false };
    //   })(state);
    default:
      return state;
  }
}
