import { Howl } from "howler";

export const callAudio = new Howl({ src: "/media/audio/call-sound.mp3" });

export const callDriveThru = new Howl({
  src: "/media/audio/driveThruArrived.mp3",
  loop: true,
});

const callPendingOrder = new Howl({
  src: "/media/audio/call-sound.mp3",
  loop: true,
});

// export const callDriveThruAudio = (function () {
//   let executed = false;
//   return function (stop) {
//     if (stop) {
//       callDriveThru.stop();

//       return;
//     }

//     if (!executed && !stop) {
//       executed = true;
//       callDriveThru.play();
//     }
//   };
// })();

// export const callPendingOrderAudio = (function () {
//   let executed = false;
//   return function (stop) {
//     console.log("executed", executed);
//     if (stop) {
//       console.log("stop sound");
//       callPendingOrder.stop();
//       executed = false;
//       return;
//     }

//     if (!executed && !stop) {
//       executed = true;
//       console.log("start sound");
//       callPendingOrder.play();
//     }
//   };
// })();

export const callAudioLoop = (function () {
  let executedPending = false;
  let executedDrive = false;

  return function (type, stop) {
    if (type === "drive") {
      executedPending = false;
      if (stop) {
        callDriveThru.stop();
        executedDrive = false;
        return;
      }

      if (!executedDrive && !stop) {
        executedDrive = true;
        callDriveThru.play();
        callPendingOrder.stop();
        }
    } else if (type === "pending" && !executedDrive) {
      if (stop) {
        callPendingOrder.stop();
        executedPending = false;
        return;
      }

      if (!executedPending && !stop) {
        executedPending = true;
        callPendingOrder.play();
      }
    } 
  };
})();
