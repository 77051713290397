import { func } from "prop-types";
import React from "react";
import Modal from "react-modal";
import langs from "../../utills/langs";
import SimpleModal from "../SimpleModal";
// import "./LogOutModal.css";

Modal.setAppElement("#root");

const LogOutModal = ({ onAccept, ...modalProps }) => (
  <SimpleModal
    {...modalProps}
    onYes={onAccept}
    question={langs.translate("logout_from_wenu")}
  />
);

LogOutModal.propTpes = {
  onAccept: func.isRequired,
  onRequestClose: func.isRequired,
};

export default LogOutModal;
