import * as types from "../types";

const initialState = {
  orders: [],
  calls: false,
  bills: [],
  reservations: [],
};

export default function unseenReducer(state = initialState, { type, payload }) {
  switch (type) {
    // case types.ADD_UNSEEN_ORDER: {
    // 	return {
    // 		...state,
    // 		orders: state.orders.concat(payload)
    // 	};
    // }

    // case types.REMOVE_UNSEEN_ORDER: {
    // 	return {
    // 		...state,
    // 		orders: state.orders.filter(orderId => orderId !== payload)
    // 	};
    // }

    case types.ADD_UNSEEN_CALL:
      return { ...state, calls: true };

    case types.REMOVE_UNSEEN_CALL:
      return { ...state, calls: false };

    // case types.ADD_UNSEEN_BILL:
    // 	return Object.assign({}, state, {
    // 		bills: state.bills.concat(payload)
    // 	});

    // case types.REMOVE_UNSEEN_BILL:
    // 	return Object.assign({}, state, {
    // 		bills: state.bills.filter(c => c !== payload)
    // 	});

    case types.ADD_UNSEEN_RESERVATION:
      return { ...state, reservations: state.reservations.concat(payload) };

    case types.REMOVE_UNSEEN_RESERVATIONS:
      return {
        ...state,
        reservations: [],
      };

    default:
      return state;
  }
}
