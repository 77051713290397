import * as React from "react";
import Icon from "react-icons-kit";
import { arrows_slim_left as LeftIcon } from "react-icons-kit/linea/arrows_slim_left";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as userActions from "../store/actions/userActions";
import useIsAdmin from "../hooks/useIsAdmin";
import "./RequireAdmin.scss";

/**
 * @typedef {{children: any}} LocalProps
 * @param {import("react-redux").ConnectedProps<typeof connector>} props
 * @returns

 */
function RequireAdmin({ children, logout }) {
  const isAdmin = useIsAdmin();

  const navigate = useNavigate();

  const handleLogOut = () => {
    logout();
    navigate("/", { replace: true });
  };

  if (!isAdmin) {
    return (
      <div className="require-admin">
        <h1>You don&apos;t have access to this page</h1>
        <p>Would you like to log in with a different account?</p>

        <div className="require-admin__actions">
          <Link to="/orders">
            <Icon icon={LeftIcon} size={24} />
            &nbsp;&nbsp;&nbsp; Back to safty
          </Link>
          <button
            className="acc-btn acc-btn--filled"
            onClick={handleLogOut}
            type="button"
          >
            Log Out
          </button>
        </div>
      </div>
    );
  }

  return children;
}

const connector = connect(null, { logout: userActions.logout });
export default connector(RequireAdmin);
