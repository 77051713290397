/*


  TODO: move deliveryReducer & deliveryOrdersReducer Actions to seperate files


*/

import * as types from "../types";
import axios from "../../providers/axios";
import * as storage from "../../providers/storage";

export const getDeliveryOrders = () => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);
    const url = "/api/delivery/orders";

    axios
      .get(url)
      .then((response) => {
        console.log("DELIVERY_ACTION");
        const { data } = response;
        dispatch({ type: types.SET_DELIVERY_ORDERS, payload: data });
      })
      .catch((err) => {
        console.log("DRIVE ORDERS::", err);
        return Promise.reject(err);
      });
  }
};

export const resetDeliveryOrder = (deviceId) => (dispatch) =>
  axios
    .post(`/api/delivery/resetOrder`, {
      deviceId,
    })
    .then(() => {
      dispatch({ type: types.RESET_DELIVERY, payload: deviceId });
    })
    .catch((err) => {
      console.log("RESET DELIVERY::", err);
      return Promise.reject(err);
    });

export const deliveryOrdersGet = (deviceId) => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);

    axios
      .get(`/api/delivery/order/${deviceId}`)
      .then((response) => {
        const { data } = response;
        if (data.orders[0]) {
          dispatch({
            type: types.NORMALIZE_DELIVERY_ORDERS,
            payload: data.orders[0],
          });
        } else {
          dispatch({ type: types.RESET_DELIVERY, payload: deviceId });
        }
      })
      .catch((err) => {
        console.log("deliveryOrdersGet:: ", err);
        return Promise.reject(err);
      });
  }
};

/**
 *
 * @param {Object} params - data to send to the server
 * @param {number} params.orderId
 */
export const changeDeliveryOrderStatus =
  ({ orderId }) =>
  (dispatch) => {
    const token = storage.get("token");
    if (token) {
      axios
        .post(`/api/orders/${orderId}/updateStatus`, { status: "ACCEPTED" })
        .then(() => {
          console.log("disabled disptach");
          // dispatch({
          //   type: types.CHANGE_DELIVERY_ORDER_STATUS,
          //   payload: { status: "ACCEPTED" },
          // });
        })
        .catch((err) => {
          console.log("deliveryActions::changeDeliveryOrderStatus", err);
          return Promise.reject(err);
        });
    }
  };

/**
 * toggle food
 * @param {Object} param
 * @param {number} param.orderDetailId - unique identifier of orderItem
 * @param {boolean} param.deleted - next state
 */
export const changeDeliveryFoodDeleted =
  ({ orderDetailId, deleted }) =>
  (dispatch) => {
    const token = storage.get("token");
    if (token) {
      axios
        .post(`/api/orderDetails/${orderDetailId}/updateStatus`, {
          status: deleted ? "REJECTED" : "PENDING",
        })
        .then(() => {
          dispatch({
            type: types.CHANGE_DELIVERY_FOOD_DELETED,
            payload: { orderDetailId, deleted },
          });
        })
        .catch((err) => {
          console.log("deliveryActions::changeDeliveryFoodDeleted", err);
          return Promise.reject(err);
        });
    }
  };

/**
 * @deprecated resetDeliveryOrder does the same thing because there is only one order
 *
 * @param {number} orderId
 * @returns
 */
export const hideDeliveryOrder = (orderId) => (dispatch) => {
  const token = storage.get("token");
  if (token) {
    axios.setHeader("token", token);
    axios
      .post(`/api/orders/${orderId}/hide`)
      .then(() => {
        dispatch({
          type: types.HIDE_DELIVERY_ORDER,
          payload: orderId,
        });
      })
      .catch((err) => {
        console.log("deliveryActions::hideDeliveryOrder", err);
        return Promise.reject(err);
      });
  }
};
