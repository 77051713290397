import { useSelector } from "react-redux";
import useStorageDefault from "./useStorageDefault";

export default function useToken() {
  const token = useStorageDefault(
    "token",
    useSelector((state) => state.userReducer.token)
  );

  return token;
}
