import * as types from "../types";
// import { toast } from "react-toastify";
// import langs from "../../utills/langs";

const initialState = {
  disabled: false,
  tablesGot: [],
  zonesGot: [],
  restaurants: [], // temporary
  zones: [], // temporary
};

export default function tableReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_DISABLED:
      return { ...state, disabled: true };
    case types.ZONE_UPDATE:
      return (() => {
        if (!action.payload || action.payload.error) {
          return { ...state, disabled: false };
        }
        const filtered1 = state.zonesGot.filter(
          (el) => el.id !== action.payload.id
        );
        const filtered2 = {
          ...state.zonesGot.filter((el) => el.id === action.payload.id)[0],
        };
        filtered2.zoneNum = action.payload.zoneNum;
        filtered2.zoneName = action.payload.zoneName;
        return {
          ...state,
          zonesGot: [...filtered1, filtered2].sort(
            (a, b) => a.zoneName > b.zoneName
          ),
          disabled: false,
        };
      })(state, action);
    case types.ZONES_GET:
      return (() => {
        if (!action.payload || action.payload.error) {
          return { ...state, disabled: false };
        }
        action.payload.zonesGot.sort((a, b) => a.zoneName > b.zoneName);
        return { ...state, ...action.payload, disabled: false };
      })(state);
    case types.ZONE_ADD:
      return (() => {
        if (!action.payload || action.payload.error) {
          return { ...state, disabled: false };
        }
        return {
          ...state,
          zonesGot: [...state.zonesGot, action.payload],
          disabled: false,
        };
      })(state);
    case types.ZONE_REMOVE:
      return (() => {
        const filtered = state.zonesGot.filter(
          (el) => el.zoneNum !== action.payload.zoneNum
        );
        return {
          ...state,
          zonesGot: filtered,
          disabled: false,
        };
      })(state);

    case types.TABLE_UPDATE:
      return (() => {
        if (!action.payload || action.payload.error) {
          return { ...state, disabled: false };
        }
        const filtered1 = state.tablesGot.filter(
          (el) => el.id !== action.payload.id
        );
        const filtered2 = {
          ...state.tablesGot.filter((el) => el.id === action.payload.id)[0],
        };
        filtered2.zoneNum = action.payload.zoneNum;
        filtered2.zoneName = action.payload.zoneName;
        filtered2.tableNum = action.payload.tableNum;
        filtered2.tableName = action.payload.tableName;
        filtered2.allowOrders = action.payload.allowOrders;
        filtered2.url = action.payload.url;
        filtered2.hash = action.payload.hash;
        return {
          ...state,
          tablesGot: [...filtered1, filtered2],
          disabled: false,
        };
      })(state, action);
    case types.TABLES_GET:
      if (!action.payload || action.payload.error) {
        return { ...state, disabled: false };
      }
      // action.payload.zonesGot.sort((a, b) => a.zoneName > b.zoneName)
      return { ...state, ...action.payload, disabled: false };

    case types.TABLE_ADD:
      if (!action.payload || action.payload.error) {
        return { ...state, disabled: false };
      }
      return {
        ...state,
        tablesGot: [...state.tablesGot, action.payload],
        disabled: false,
      };

    case types.TABLE_REMOVE:
      return (() => {
        const filtered = state.tablesGot.filter(
          (el) => el.id !== action.payload.tableId
        );
        return {
          ...state,
          tablesGot: filtered,
          disabled: false,
        };
      })(state);

    // TEMPORARY STUFF BELOW
    case types.RESTAURANT_LIST_GET:
      if (!action.payload || action.payload.error) {
        return { ...state, disabled: false };
      }
      return { ...state, ...action.payload, disabled: false };

    case types.ZONE_LIST_GET:
      if (!action.payload || action.payload.error) {
        return { ...state, disabled: false };
      }
      return { ...state, ...action.payload, disabled: false };

    case types.TABLE_ADD_RESTAURANT:
      if (!action.payload || action.payload.error) {
        return { ...state, disabled: false };
      }
      return state;

    default:
      return state;
  }
}
