// @ts-check

import React from "react";
import MTextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useLabelStyles = makeStyles({
  root: {
    "&$focused": {
      color: "#f73e1f",
    },

    "&$error": {
      color: "#ff9100",
    },
  },
  focused: {},
  error: {},
});

const useInputStyles = makeStyles({
  input: {
    boxShadow: "none !important",
  },
  underline: {
    "&:after": {
      borderColor: "#f73e1f",
    },
    "&$error:after": {
      borderColor: "#ff9100",
    },
  },
  error: {},
});

/** @param {import("@material-ui/core").TextFieldProps} props */
function TextField(props) {
  const labelClasses = useLabelStyles();
  const inputClasses = useInputStyles();
  const helperTextClasses = useLabelStyles();

  const { InputLabelProps, InputProps } = props;
  return (
    <MTextField
      {...props}
      InputLabelProps={{ classes: labelClasses, ...InputLabelProps }}
      InputProps={{
        classes: inputClasses,
        ...InputProps,
      }}
      FormHelperTextProps={{ classes: helperTextClasses }}
    />
  );
}

export default TextField;
