import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import * as storage from "../../providers/storage";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 200,
  },
}));

/** @param {import("react-redux").ConnectedProps<typeof connector>} props */
const AdminSelector = ({ changeUser, userId }) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BACK_URL}api/admin/getRestaurants`,
          headers: {
            token: storage.get("wenuAdminToken"),
          },
        });

        setUsers(data);
      } catch (error) {
        console.log("ERRO", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = async ({ target: { value } }) => {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BACK_URL}api/admin/changeRestaurant`,
        data: {
          restaurantId: value,
        },
        headers: {
          token: storage.get("wenuAdminToken"),
        },
      });

      storage.set("token", data.token);
      changeUser(data);
      // might be unnecessary
      window.location.reload();
    } catch (error) {
      console.log("CHANGE_USER_ERR:: ", error);
    }
  };

  return (
    <div className="admin-selector">
      <FormControl className={classes.formControl}>
        <InputLabel>Restaurant Name</InputLabel>
        {users.length > 0 && userId && (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userId}
            onChange={handleChange}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.restaurantName}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.userReducer.user?.id,
});

const mapDispatchToProps = (dispatch) => ({
  changeUser: (payload) => dispatch({ type: "USER_LOGIN", payload }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AdminSelector);
