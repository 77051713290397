import Axios from "axios";
 
import { stripTrailingSlash } from "../utills/helpers";

class Service {
  constructor() {
    const service = Axios.create({
      baseURL: stripTrailingSlash(process.env.REACT_APP_BACK_URL),
    });
    this.service = service;
    this.handleError = this.handleError.bind(this);
    service.defaults.headers.common.token = "";
    service.interceptors.response.use(this.handleSuccess, this.handleError);
  }

  setHeader = (key, value) => {
    this.service.defaults.headers.common[key] = value;
  };

  handleSuccess = (response) => response;

  handleError = (error) => {
    console.log("Axios error:", error);
    return Promise.reject(error);
  };

  get(path) {
    return this.service.get(path);
  }

  post(path, payload = undefined) {
    // console.log('sending post: ', path, payload)

    return this.service.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload,
    });
  }

  delete(path) {
    return this.service.delete(path);
  }
}

export default new Service();
