import * as types from "../types";

// import { toast } from "react-toastify";
// import langs from "../../utills/langs";

export default function deliveryReducer(state = [], action) {
  switch (action.type) {
    case types.SET_DELIVERY_ORDERS:
      return action.payload;

    case types.ADD_DELIVERY_ORDER: {
      const updatedState = [...state, action.payload];

      const sortedStaet = updatedState.sort((o) =>
        o.status === "PENDING" ? -1 : 1
      );
      return sortedStaet;
    }
    case types.RESET_DELIVERY: {
      const newState = state.filter(
        (order) => order.deviceId !== +action.payload
      );
      return newState;
    }
    case "CHANGE_DELIVERY_STATUS": {
      const { status, deviceId } = action.payload;
      const updatedState = state.map((order) => {
        const _order = order;
        if (+_order.deviceId === +deviceId) {
          _order.status = status;
        }
        return _order;
      });

      const sortedStaet = updatedState.sort((o) =>
        o.status === "PENDING" ? -1 : 1
      );
      return sortedStaet;
    }
    default:
      return state;
  }
}
