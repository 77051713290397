export const USER_LOGIN = "USER_LOGIN";
export const CHANGE_LANG = "CHANGE_LANG";
export const USER_LOGOUT = "USER_LOGOUT";
export const LANGUAGES_GET = "LANGUAGES_GET";
export const LANGUAGE_ADD = "LANGUAGE_ADD";
export const LANGUAGE_MAKE_PRIMARY = "LANGUAGE_MAKE_PRIMARY";
export const LANGUAGE_REMOVE = "LANGUAGE_REMOVE";
export const LANGUAGE_PUBLISH = "LANGUAGE_PUBLISH";
export const SET_DISABLED = "SET_DISABLED";
// --- categoryReducer
export const CATEGORIES_GET = "CATEGORIES_GET";
export const CATEGORY_ADD = "CATEGORY_ADD";
export const CATEGORY_UPDATE = "CATEGORY_UPDATE";
export const CATEGORY_REMOVE = "CATEGORY_REMOVE";
export const CATEGORY_PUBLISH = "CATEGORY_PUBLISH";
export const CATEGORY_REORDER = "CATEGORY_REORDER";
export const CATEGORY_REORDER_SAVE = "CATEGORY_REORDER_SAVE";
export const CATEGORY_REORDER_CANCEL = "CATEGORY_REORDER_CANCEL";

// ---foodReducer
export const FOODS_GET = "FOODS_GET";
export const FOOD_ADD = "FOOD_ADD";
export const FOOD_UPDATE = "FOOD_UPDATE";
export const FOOD_REMOVE = "FOOD_REMOVE";
export const FOOD_PUBLISH = "FOOD_PUBLISH";
export const FOOD_IMAGE_REMOVE = "FOOD_IMAGE_REMOVE";
export const FOODS_GET_BY_CATEGORIES = "FOODS_GET_BY_CATEGORIES";

// --- tableReducer (zones)
export const ZONES_GET = "ZONES_GET";
export const ZONE_ADD = "ZONE_ADD";
export const ZONE_UPDATE = "ZONE_UPDATE";
export const ZONE_REMOVE = "ZONE_REMOVE";

// --- tableReducer (tables)
export const TABLES_GET = "TABLES_GET";
export const TABLE_ADD = "TABLE_ADD";
export const TABLE_UPDATE = "TABLE_UPDATE";
export const TABLE_REMOVE = "TABLE_REMOVE";

// --- activeTablesReducer
// export const SET_TABLES_WITH_ORDERS = "SET_TABLES_WITH_ORDERS";
export const SET_ACTIVE_TABLES = "SET_ACTIVE_TABLES";
export const ADD_ACTIVE_TABLE = "ADD_ACTIVE_TABLE";

// --- driveThruReducer
export const SET_DRIVE_THRU_ORDERS = "SET_DRIVE_THRU_ORDERS";
export const ADD_DRIVE_THRU_ORDER = "ADD_DRIVE_THRU_ORDER";
export const RESET_DRIVE_THRU = "RESET_DRIVE_THRU";

// --- driveThruOrdersReducer
export const NORMALIZE_DRIVE_THRU_ORDERS = "NORMALIZE_DRIVE_THRU_ORDERS";
export const CHANGE_DRIVE_THRU_ORDER_STATUS = "CHANGE_DRIVE_THRU_ORDER_STATUS";
export const CHANGE_DRIVE_THRU_FOOD_DELETED = "CHANGE_DRIVE_THRU_FOOD_DELETED";
export const HIDE_DRIVE_THRU_ORDER = "HIDE_DRIVE_THRU_ORDER";

// --- deliveryReducer
export const SET_DELIVERY_ORDERS = "SET_DELIVERY_ORDERS";
export const ADD_DELIVERY_ORDER = "ADD_DELIVERY_ORDER";
export const RESET_DELIVERY = "RESET_DELIVERY";

// --- deliveryOrdersReducer
export const NORMALIZE_DELIVERY_ORDERS = "NORMALIZE_DELIVERY_ORDERS";
export const CHANGE_DELIVERY_ORDER_STATUS = "CHANGE_DELIVERY_ORDER_STATUS";
export const CHANGE_DELIVERY_FOOD_DELETED = "CHANGE_DELIVERY_FOOD_DELETED";
export const HIDE_DELIVERY_ORDER = "HIDE_DELIVERY_ORDER";

// --- setupReducer
export const SETUP_GET = "SETUP_GET";
export const SETUP_DONE = "SETUP_DONE";

//---
export const ORDERS_BY_ZONENUM_GET = "ORDERS_BY_ZONENUM_GET"; // redundant
export const ORDERS_BY_RESTAURANT_GET = "ORDERS_BY_RESTAURANT_GET"; // redundant
export const ORDER_BY_ID_GET = "ORDER_BY_ID_GET"; // redundant
export const ORDER_UPDATE = "ORDER_UPDATE"; // redundant

// --- activeReservationsReducer + outdatedReservationsReducer
export const ACTIVE_RESERVATIONS_GET = "ACTIVE_RESERVATIONS_GET";
export const OUTDATED_RESERVATIONS_GET = "OUTDATED_RESERVATIONS_GET";
export const RESERVATIONS_ACCEPT = "RESERVATIONS_ACCEPT";
export const RESERVATIONS_DECLINE = "RESERVATIONS_DECLINE";
export const RESERVATIONS_CANCEL = "RESERVATIONS_CANCEL";
export const ACTIVE_RESERVATIONS_CLEAR = "ACTIVE_RESERVATIONS_CLEAR";

// --- callReducer
export const CALL_BY_ID_GET = "CALL_BY_ID_GET";
export const CALLS_GET = "CALLS_GET";
export const TERMINATE_CALLS = "TERMINATE_CALLS";
export const TERMINATE_CALL = "TERMINATE_CALL";

// --- trableOrdersReducer
export const NORMALIZE_TABLE_ORDERS = "NORMALIZE_TABLE_ORDERS";
export const ADD_FOOD_TO_ORDER = "ADD_FOOD_TO_ORDER";
export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const CHANGE_FOOD_DELETED = "CHANGE_FOOD_DELETED";
export const RESET_TABLE = "RESET_TABLE";
export const HIDE_ORDER = "HIDE_ORDER";

// --- unseenReducer (epic name, ik)
export const ADD_UNSEEN_ORDER = "ADD_UNSEEN_ORDER";
export const REMOVE_UNSEEN_ORDER = "REMOVE_UNSEEN_ORDER";
export const ADD_UNSEEN_CALL = "ADD_UNSEEN_CALL";
export const REMOVE_UNSEEN_CALL = "REMOVE_UNSEEN_CALL";
export const ADD_UNSEEN_BILL = "ADD_UNSEEN_BILL";
export const REMOVE_UNSEEN_BILL = "REMOVE_UNSEEN_BILL";
export const ADD_UNSEEN_RESERVATION = "ADD_UNSEEN_RESERVATION";
export const REMOVE_UNSEEN_RESERVATIONS = "REMOVE_UNSEEN_RESERVATIONS";

// --- pendingCounterReducer
export const SET_PENDING_ORDERS = "SET_PENDING_ORDERS";
export const SET_PENDING_RESERVATIONS = "SET_PENDING_RESERVATIONS";
export const SET_PENDING_BILLS = "SET_PENDING_BILLS";

// --- toast middleWare
export const TOAST = "TOAST";

// --- reportsReducer
export const SET_REPORTS = "SET_REPORTS";

// --- registrationReducer
export const REGISTRATION_GET = "REGISTRATION_GET";
export const REGISTRATION_DONE = "REGISTRATION_DONE";

export const RESTAURANT_LIST_GET = "RESTAURANT_LIST_GET"; // temporary
export const ZONE_LIST_GET = "ZONE_LIST_GET"; // temporary
export const TABLE_ADD_RESTAURANT = "TABLE_ADD_RESTAURANT"; // temporary

// --- driverLocationReducer
export const SET_DRIVER_LOCATION = "SET_DRIVER_LOCATION";
export const REMOVE_DRIVER_LOCATION = "REMOVE_DRIVER_LOCATION";

// --- userReducer (TFA types)
export const UPDATE_TFA_STATUS = "UPDATE_TFA_STATUS";
