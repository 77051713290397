// TODO: implement instanciatable storage that stores key-value pairs with configurable
// prefixes, so that, calling storage.clear() will only delete storage it controls.
// eg: userStorage, favoritesStorage, uiStorage...

const prefix = "wenu2_";
export const set = (key, value) => {
  const _value = {
    key: value,
  };
  localStorage.setItem(prefix + key, JSON.stringify(_value));
};

export const get = (key) => {
  const item = localStorage.getItem(prefix + key);
  if (item) return JSON.parse(item).key;
  return null;
};

export const unset = (key) => {
  localStorage.removeItem(prefix + key);
};

export const clear = () => {
  localStorage.clear();
};
