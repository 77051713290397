import React from "react";
// import "nprogress/nprogress.css";
// import "./FullPageLoader.scss";
import nprogress from "../lib/nprogress/nprogress";

export default function FullPageLoader() {
	React.useEffect(() => {
		nprogress.start();

		return () => {
			nprogress.done();
		};
	});

	return null;
}
